import React, { useState, useEffect } from "react";
import axios from "axios";
import CookieService from "encrypted-cookie";
import { useNavigate } from "react-router-dom";
import "../../styles/FeaturedProfiles.css";
import man1 from "../../images/man1.jpg";
import man2 from "../../images/man2.jpg";
import man3 from "../../images/man3.jpg";
import lady1 from "../../images/lady1.jpg";
import lady2 from "../../images/lady2.jpg";
import lady3 from "../../images/lady3.jpg";
import lady4 from "../../images/lady4.jpg";
import { GiQueenCrown } from "react-icons/gi";
const backenduRL = process.env.REACT_APP_API_UR;

const FeaturedProfiles = () => {
  const [datagiv, setDatagiv] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  console.log(datagiv);

  useEffect(() => {
    const token = CookieService.checkCookie("token");

    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const profiles = [
    {
      name: "John Doe",
      age: 29,
      profession: "Software Engineer",
      location: "New York, USA",
      image: man1,
    },
    {
      name: "Jane Smith",
      age: 27,
      profession: "Doctor",
      location: "Los Angeles, USA",
      image: man2,
    },
    {
      name: "Sam Wilson",
      age: 30,
      profession: "Architect",
      location: "Chicago, USA",
      image: man3,
    },
    {
      name: "Anna Johnson",
      age: 28,
      profession: "Teacher",
      location: "Miami, USA",
      image: man1,
    },
    {
      name: "Swetha",
      age: 29,
      profession: "Software Engineer",
      location: "Hyderabad, Telangana",
      image: lady1,
    },
    {
      name: "Pravalika Reddy",
      age: 27,
      profession: "Doctor",
      location: "Vizag, AP",
      image: lady2,
    },
    {
      name: "Keerthi sharma",
      age: 30,
      profession: "Architect",
      location: "Mumbai, India",
      image: lady3,
    },
    {
      name: "Samantha",
      age: 28,
      profession: "Teacher",
      location: "Jaipur, India",
      image: lady4,
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("personal") !== "undefined") {
      const personalDataString = localStorage.getItem("personal");
      const personalData = JSON.parse(personalDataString);

      if (personalData) {
        let genderParam = "";
        let heightCM = "";
        let age = "";
        let profession = "";
        if (
          personalData.height &&
          personalData.height !== "undefined" &&
          personalData.height !== "NA"
        ) {
          heightCM = personalData.height;
        }

        if (personalData.lookingFor) {
          genderParam = personalData.lookingFor;
        } else if (personalData["Bride/Bridegroom"]) {
          if (personalData["Bride/Bridegroom"] === "Bride") {
            genderParam = "Bridegroom";
          } else {
            genderParam = "Bride";
          }
        }
        if (personalData.Age && personalData.Age !== "NA") {
          age = personalData.Age;
        }
        if (personalData.Profession && personalData.Profession !== "NA") {
          profession = personalData.Profession;
        }
        if (genderParam) {
          var custom_headers = {
            "Content-Type": "application/json",
            Authorization: CookieService.getCookie("token"),
          };

          axios
            .get(
              `/api/users/userFeaturedProfiles`,

              {
                params: { gender: genderParam, heightCM, age, profession },
                headers: custom_headers,
              }
            )
            .then((response) => {
              const data = response.data;
              setDatagiv(data);
              console.log(data);
            })
            .catch((error) => {
              console.error("Error fetching users data:", error);
            });
        }
      }
    }
  }, []);

  const handleProfileClick = (jioId) => {
    navigate(`/user/${jioId}`);
  };

  return (
    <div>
      <section id="featured" className="featured-profiles">
        <h2>Featured Profiles</h2>
        <div className="profiles-wrapper">
          <div className="profiles">
            {isAuthenticated
              ? datagiv.map((profile, index) => (
                  <div key={index} className="profile-card">
                    {profile.imageData && profile.imageData.length > 0 ? (
                      <img
                        onClick={() => handleProfileClick(profile.JIOID)}
                        className="img-back"
                        src={`data:image/jpeg;base64,${profile.imageData[0]}`}
                        alt={`Profile ${profile._id} image 1`}
                      />
                    ) : (
                      <img src={require("../../images/OIP.jpeg")} />
                    )}

                    <h3 onClick={() => handleProfileClick(profile.JIOID)}>
                      {profile.Name}
                      {profile.membership_status &&
                      profile.membership_status !== "Unpaid" &&
                      profile.membership_status !== "Expired" ? (
                        <span>
                          {" "}
                          <GiQueenCrown
                            className={`membership-status ${profile.membership_status}`}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </h3>
                    <p className="id">
                      JIO_ID: {" "}
                      <span>J{profile.JIOID}</span>
                    </p>
                    <p>Age: {profile.Age}</p>
                    <p>Height: {profile.height || "Not Specified"}</p>
                    <p>Profession: {profile.Profession}</p>

                    <p>Gender: {profile["Bride/Bridegroom"]}</p>
                  </div>
                ))
              : profiles.map((profile, index) => (
                  <div key={index} className="profile-card">
                    <img src={profile.image} />
                    <h3>{profile.name}</h3>
                    <p>Age: {profile.age}</p>
                    <p>Profession: {profile.profession}</p>
                    <p>Location: {profile.location}</p>
                  </div>
                ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FeaturedProfiles;
