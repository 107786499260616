import React, { useState, useContext } from "react";
import "../../styles/allusers.css";
import { UserContext } from "../../context/UserContext.js";
import Filteration from "../Home/filteration.js"; // Adjust the path accordingly

const AllUsers = (props) => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const { errfil, setErrFil } = useContext(UserContext);
  console.log(userDetails, "userDetails");
  return (
    <div className="all-users">
      <Filteration />
      {errfil ? (
        <p>{errfil}</p>
      ) : (
        <p>There are{userDetails.length} Profiles Match</p>
      )}
      <div className="matched-users">
        {userDetails.map((each, index) => (
          <div className="each-user">
            {each.imageData && each.imageData.length > 0 ? (
              <>
                <img
                  className="img-Featured"
                  src={`data:image/jpeg;base64,${each.imageData[0]}`}
                  alt={`Profile ${each._id} image`}
                />
              </>
            ) : (
              <img src={require("../../images/OIP.jpeg")} alt="Default" />
            )}
            <div className="some-details">
              <h2>{each.Name}</h2>
              <h4>
                <span>JIO_ID: </span>
                {each.JIOID}
              </h4>
              <p>Age: {each.Age}</p>
              <p>Denomination: {each.Denomination}</p>
              <p>Bride or Groom: {each["Bride/Bridegroom"]}</p>
              <p>Cast: {each.Cast}</p>
              <p>Marital Status: {each["MaritalStatus"]}</p>
              <p>City: {each.City}</p>
              <p>Salary: {each["Salary-PA_Standardized"] || "Not-Specified"}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllUsers;
