// src/contexts/UserContext.js
import React, { createContext, useState } from "react";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState([]);
  const [userImages, setUserImages] = useState([]);
  const [errfil, setErrFil] = useState("");

  return (
    <UserContext.Provider
      value={{
        userDetails,
        setUserDetails,
        userImages,
        setUserImages,
        errfil,
        setErrFil,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
