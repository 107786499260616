import React, { useState } from "react";
import "../../styles/help.css";
import Contact from "./contact";
import Feedback from "./feedback";

const Help = () => {
  const [selectMethod, setSelectMethod] = useState("contactus");
  const handleMethodChange = (method) => {
    setSelectMethod(method);
  };
  return (
    <div className="help-page">
      <div className="help">
        <div className="help-header">
          <h1>Help & Support</h1>
          <img src={require("../../images/help.jpeg")} />
        </div>
        <div className="help-methods">
          <button
            onClick={() => handleMethodChange("contactus")}
            className={selectMethod === "contactus" ? "active" : ""}
          >
            Contact Us
          </button>

          <button
            onClick={() => handleMethodChange("feedbacks")}
            className={selectMethod === "feedbacks" ? "active" : ""}
          >
            FeedBack
          </button>
        </div>
        <div className="contact-details">
          {selectMethod === "contactus" && <Contact />}
          {selectMethod === "feedbacks" && <Feedback />}
        </div>
      </div>
    </div>
  );
};

export default Help;
