import React, { useState, useEffect } from "react";
import "../../styles/profileid.css";
import CookieService from "encrypted-cookie";
import axios from "axios";
import { GiQueenCrown } from "react-icons/gi";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";

const ProfileId = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("JIOID");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [errorContact, setErrorContact] = useState({});
  const [loading, setLoading] = useState(false);
  const [showVal, setShowVal] = useState({});
  const [count, setCount] = useState("");
  const [remainingDays, setRemainingDays] = useState({});
  const [mydetails, setMydetails] = useState("");
  const [favorites, setFavorites] = useState([]); // State for favorites

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!searchValue) {
      setError("Please enter a valid search value.");
      return;
    }
    setLoading(true);
    setError(null);

    const custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };

    const searchParam =
      searchType === "JIOID" ? { id: searchValue } : { Name: searchValue };

    axios
      .get(`/api/users/GetUsersById`, {
        params: searchParam,
        headers: custom_headers,
      })
      .then((response) => {
        setData(response.data.users);
      })
      .catch((error) => {
        setError("Error fetching users data. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleReveal = (userId) => {
    const personalDataString = localStorage.getItem("personal");
    const personalData = JSON.parse(personalDataString);
    setCount("");
    setMydetails(personalData);
    setErrorContact({});

    setRemainingDays((prev) => ({ ...prev, [userId]: null }));

    axios
      .get(`/api/users/CheckContactLimit`, {
        params: { id: personalData.JIOID },
      })
      .then((response) => {
        if (response.data.message === "Contact Revealed") {
          if (response.data.remainingDays) {
            const { months, days } = response.data.remainingDays;
            if (months == 0 && days == 0) {
              setErrorContact((prev) => ({
                ...prev,
                [userId]: "Your Package is Expired",
              }));
              return;
            }
            setRemainingDays((prev) => ({
              ...prev,
              [userId]: `${months} month${months !== 1 ? "s" : ""} and ${days} day${days !== 1 ? "s" : ""}`,
            }));
          }
          setCount(response.data.limitcount);
          setShowVal((prev) => ({ ...prev, [userId]: true }));

          return axios.post("/api/users/logView", {
            viewerJioId: personalData.JIOID,
            viewedProfileJioId: userId,
          });
        }
      })
      .then(() => {
        console.log("Profile view logged successfully.");
      })
      .catch((error) => {
        setErrorContact((prev) => ({
          ...prev,
          [userId]: error.response?.data?.error || "An error occurred",
        }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Add to favorites
  const handleToggleFavorite = async (userId) => {
    const personalDataString = localStorage.getItem("personal");
    const personalData = JSON.parse(personalDataString);
    const loggedInUserId = personalData.JIOID;
    const isFavorite = favorites.includes(userId);

    try {
      const token = CookieService.getCookie("token");
      const headers = { Authorization: token };

      if (isFavorite) {
        await axios.post('/api/users/removeFavorite', {
          userId: loggedInUserId,
          favoriteJioId: userId,
        }, { headers });
        setFavorites((prev) => prev.filter((id) => id !== userId)); // Remove from favorites
      } else {
        await axios.post('/api/users/addFavorite', {
          userId: loggedInUserId,
          favoriteJioId: userId,
        }, { headers });
        setFavorites((prev) => [...prev, userId]); // Add to favorites
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  useEffect(() => {
    // Fetch current user's favorites when the component mounts
    const fetchFavorites = async () => {
      const personalDataString = localStorage.getItem("personal");
      const personalData = JSON.parse(personalDataString);
      const loggedInUserId = personalData.JIOID;

      try {
        const token = CookieService.getCookie("token");
        const headers = { Authorization: token };

        const response = await axios.get(`/api/users/getFavorites`, {
          headers,
          params: { userId: loggedInUserId },
        });

        setFavorites(response.data.favorites);
      } catch (error) {
        console.error("Error fetching favorites:", error);
      }
    };

    fetchFavorites();
  }, []);

  return (
    <div className="bottom-section">
      <form onSubmit={handleSubmit}>
        <div className="radios">
          <label>
            <input
              type="radio"
              name="searchType"
              value="JIOID"
              checked={searchType === "JIOID"}
              onChange={handleSearchTypeChange}
            />
            Search by JIOID
          </label>
          <label>
            <input
              type="radio"
              name="searchType"
              value="Name"
              checked={searchType === "Name"}
              onChange={handleSearchTypeChange}
            />
            Search by Name
          </label>
        </div>
        <div className="searchSubmit">
          <input
            type="text"
            placeholder={`Enter ${
              searchType === "JIOID" ? "JIOID" : "Name"
            } here...`}
            value={searchValue}
            onChange={handleChange}
          />
          <button className="line-shade" type="submit" disabled={loading}>
            {loading ? "Loading..." : "Submit"}
          </button>
        </div>
      </form>

      {error && <p className="error-message">{error}</p>}

      <div className="profiles-container">
        {data.length > 0 &&
          data.map((each) => (
            <div key={each.JIOID} className="profile-user">
              {each.images && each.images.length > 0 ? (
                <img
                  className="profile-img"
                  src={`data:image/jpeg;base64,${each.images[0]}`}
                  alt={`Profile image for ${each.Name}`}
                />
              ) : (
                <img src={require("../../images/OIP.jpeg")} alt="default" />
              )}
              <div className="profile-info">
                <h2
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  {each.Name}
                  {each.membership_status &&
                  each.membership_status !== "Unpaid" &&
                  each.membership_status !== "Expired" ? (
                    <span>
                      {" "}
                      <GiQueenCrown
                        className={`membership-status ${each.membership_status}`}
                      />
                    </span>
                  ) : (
                    ""
                  )}
                  <div
                    onClick={() => handleToggleFavorite(each.JIOID)}
                    style={{ cursor: "pointer", position: "unset !important" }}
                  >
                    {favorites.includes(each.JIOID) ? (
                      <MdFavorite
                        className="favorite-icon inprofiled"
                        style={{ color: "red", position: "unset !important" }}
                      />
                    ) : (
                      <MdFavoriteBorder
                        className="favorite-icon inprofiled"
                        style={{ position: "unset !important" }}
                      />
                    )}
                  </div>
                </h2>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> J{each.JIOID}</span>
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> Age:</span> {each.Age}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Denomination:
                  </span>{" "}
                  {each.Denomination}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Bride or Groom:
                  </span>{" "}
                  {each["Bride/Bridegroom"]}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> Cast:</span> {each.Cast}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Marital Status:
                  </span>{" "}
                  {each.MaritalStatus}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> City:</span> {each.City}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> Salary:</span>{" "}
                  {each["Salary-PA"] || "Not-Specified"}
                </p>

                <div className="contactinfo">
                  {!showVal[each.JIOID] ? (
                    <>
                      <span
                        onClick={() => handleReveal(each.JIOID)}
                        className="line-shade"
                        style={{ width: "fit-content" }}
                      >
                        Show Contact Details
                      </span>
                      {errorContact[each.JIOID] && (
                        <p className="error-message">
                          {errorContact[each.JIOID]}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {remainingDays[each.JIOID] !== null ? (
                        <p style={{ color: "rgb(255, 111, 97)" }}>
                          You have <strong>{remainingDays[each.JIOID]}</strong>{" "}
                          remaining and can reveal <strong>{count}</strong> more
                          contacts.
                        </p>
                      ) : (
                        <p style={{ color: "rgb(255, 111, 97)" }}>
                          You can reveal <strong>{count}</strong> more contacts.
                        </p>
                      )}
                      <p>Mobile Number: {each.Mobile}</p>
                      <p>Email: {each.email}</p>
                    </>
                  )}

                  {/* Favorite button
                  <div onClick={() => handleToggleFavorite(each.JIOID)} style={{ cursor: "pointer" }}>
                    {favorites.includes(each.JIOID) ? (
                      <MdFavorite className="favorite-icon inprofiled" style={{ color: "red" }} />
                    ) : (
                      <MdFavoriteBorder className="favorite-icon inprofiled" />
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProfileId;
