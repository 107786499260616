import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/profilecreating.css"; // Import the CSS file
import { useNavigate } from "react-router";
import CookieService from "encrypted-cookie";
import { toast } from "react-toastify";

import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Slider from "react-slick";
import { Grid, Box } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cities from "cities-list";

import { CiLock } from "react-icons/ci";
import { GiInfo } from "react-icons/gi";
import { MdComputer } from "react-icons/md";
import { Link } from "react-router-dom";
import Select from "react-select";

import { Country, State, City } from "country-state-city";
import { getStatesOfCountry } from "country-state-city/lib/state";

const CreateProfile = (props) => {
  const [formData, setFormData] = useState({
    Name: "",
    Age: "",
    height: "",
    weight: "",
    Education: "",
    ["Salary-PA_Standardized"]: "",
    Country: "",
    State: "",
    City: "",
    gender: "",
    MaritalStatus: "",
    MotherTongue: "",
    livingWithFamily: "",
    Denomination: "",
    religion: "",
    Cast: "",
    lookingFor: "",
    Mobile: "",
    otp: "",
    image: [],
    AboutMe: "",
    Prefering: "",
    AboutFamily: "",
    Profession: "",
    DateOfBirth: "",
    Rashi: "",
    PlaceOfBirth: "",
    TimeOfBirth: "",
  });
  const [proimage, setimage] = useState([]);
  const [profiledataID, setprofiledataID] = useState("");
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [CustomInput, setCustomeInput] = useState({});

  const [salaryOptions, setsalaryOptions] = useState([
    "Below 2LPA",
    "2LPA - 5LPA",
    "5LPA - 10LPA",
    "10LPA - 15LPA",
    "15LPA - 20LPA",
    "20LPA - 25LPA",
    "25LPA - 30LPA",
    "30LPA - 35LPA",
    "35LPA - 40LPA",
    "40LPA - 45LPA",
    "45LPA - 50LPA",
    "50LPA - 55LPA",
    "55LPA - 60LPA",
    "60LPA - 65LPA",
    "65LPA - 70LPA",
    "70LPA - 75LPA",
    "75LPA - 80LPA",
    "80LPA - 85LPA",
    "85LPA - 90LPA",
    "90LPA - 95LPA",
    "95LPA - 1 Cr",
    "Above 1 Cr",
  ]);

  const [professionData, setprofessionData] = useState([]);

  // const [cities, setcities] = useState([]);
  const [communityData, setcommunityData] = useState([]);
  const [casteData, setcasteData] = useState([]);
  const [educationData, seteducationData] = useState([]);
  const [religion, setReligion] = useState([]);
  const [country, setcountry] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setcities] = useState([]);
  const [motherTon, setMotherTon] = useState([]);
  const [raasi, setRaasi] = useState([]);
  const [subCaste, setSubCaste] = useState([]);

  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState(null);

  const [religions, setReligions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [selectedCasteAlias, setSelectedCasteAlias] = useState("Caste");
  useEffect(() => {
    const fetchReligions = async () => {
      try {
        const response = await axios.get("/api/users/religions");
   // Ensure this is the correct structure
        setReligions(response.data);
      } catch (error) {
        console.error("Error fetching religions:", error);
      }
    };
    fetchReligions();
  }, []);

  const handleReligionChange = (selectedOption) => {
    const selectedReligion = selectedOption.value;
    const foundReligion = religions.find(
      (religion) => religion.name === selectedReligion
    );

    if (foundReligion) {
      setCasteOptions(foundReligion.castes);
      setSelectedCasteAlias(foundReligion.castealias);
      setFormData((prevState) => ({
        ...prevState,
        religion: selectedReligion,
        caste: "",
      }));
    }
  };

  const handleCasteChange = (selectedOption) => {
    setFormData((prevState) => ({ ...prevState, Cast: selectedOption.value }));
  };
  useEffect(() => {
    setCountries(Country.getAllCountries());
    setStates(State.getAllStates());
    setcities(City.getAllCities());

    const all = City.getAllCities();
  }, []);

  // Fetch states based on selected country
  useEffect(() => {
    if (formData.Country) {
      setStates(State.getStatesOfCountry(formData.Country));
    }
  }, [formData.Country]);

  // Fetch cities based on selected state
  useEffect(() => {
    if (formData.State) {
      setcities(City.getCitiesOfState(formData.Country, formData.State));
    }
  }, [formData.State]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const fileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    Promise.all(files.map(fileToBase64))
      .then((base64Images) => {
        setSelectedFiles(base64Images);
      })
      .catch((error) => {
        console.error("Error converting files to Base64:", error);
      });
  };

  // Function to generate height options
  const generateHeightOptions = () => {
    const heights = [];
    for (let feet = 4; feet <= 7; feet++) {
      for (let inches = 0; inches < 12; inches += 1) {
        let height = `${feet}ft ${inches}inch`;
        if (inches === 0) {
          height = `${feet}ft`;
        }
        heights.push(height);
      }
    }
    return heights;
  };

  useEffect(() => {
    if (formData.gender === "Male") {
      setFormData((prevData) => ({
        ...prevData,
        lookingFor: "Bride",
      }));
    } else if (formData.gender === "Female") {
      setFormData((prevData) => ({
        ...prevData,
        lookingFor: "Bridegroom",
      }));
    } else {
      // Clear lookingFor if no gender is selected
      setFormData((prevData) => ({
        ...prevData,
        lookingFor: "",
      }));
    }
  }, [formData.gender]);

  useEffect(() => {
    Dropdown();
    if (props.view) {
      currentUser();
    }
  }, [props.view]);

  const currentUser = async () => {
    const per = localStorage.getItem("personal");
    const data = JSON.parse(per);
    setImages();

    var custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };
    axios
      .get(`/api/users/getPersonalDetails`, {
        params: { val: data.email || data.Mobile },
        headers: custom_headers,
      })
      .then((response) => {
        const data = response.data;

        setFormData(data.users[0]);
        console.log(data,"data");
        setImages(data.images);

        localStorage.setItem("userimages", JSON.stringify(data.images));
      })
      .catch((error) => {
        console.error("Error fetching users data:", error);
      });
  };

  const Dropdown = async () => {
    try {
      const response = await fetch(`/api/admin/searchable-dropdown`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (response.ok) {
        var cities = [];
        var professionData = [];
        var communityData = [];
        var casteData = [];
        var educationData = [];
        var country = [];

        var religionData = [];
        var motherTongue = [];
        var subCasteData = [];
        var raasi = [];
        for (var x in result.data.raasiData) {
          raasi.push(result.data.raasiData[x].name);
        }
        for (var x in result.data.subCasteData) {
          subCasteData.push(result.data.subCasteData[x].name);
        }
        for (var x in result.data.motherTongue) {
          motherTongue.push(result.data.motherTongue[x].name);
        }
        for (var x in result.data.professionData) {
          professionData.push(result.data.professionData[x].name);
        }
        for (var x in result.data.religionData) {
          religionData.push(result.data.religionData[x].name);
        }
        for (var x in result.data.cities) {
          cities.push(result.data.cities[x].name);
        }
        for (var x in result.data.communityData) {
          communityData.push(result.data.communityData[x].name);
        }
        for (var x in result.data.casteData) {
          casteData.push(result.data.casteData[x].name);
        }
        for (var x in result.data.educationData) {
          educationData.push(result.data.educationData[x].name);
        }
        for (var x in result.data.country) {
          country.push(result.data.country[x].name);
        }

        setprofessionData(professionData);
        setcities(cities);
        setcommunityData(communityData);
        setcasteData(casteData);
        seteducationData(educationData);
        setReligion(religionData);
        setcountry(country);
        setMotherTon(motherTongue);
        setSubCaste(subCasteData);
        setRaasi(raasi);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  function calculateAge(dob) {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }
  const handleChange = (e) => {
    const { name, files, value } = e.target;

    if (name === "image") {
      const fileArray = Array.from(files);
      const imgPromises = fileArray.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => {
            resolve(reader.result);
          };

          reader.onerror = (error) => {
            reject(error);
          };
        });
      });

      Promise.all(imgPromises)
        .then((imgarr) => {
          // imgarr is an array of base64 encoded strings
          setFormData((prevData) => ({
            ...prevData,
            [name]: imgarr,
          }));
        })
        .catch((error) => {
          console.error("Error reading files:", error);
        });
    } else if (name === "DateOfBirth") {
      const age = calculateAge(value);

      const [year, month, day] = value.split("-");
      const formattedDate = `${day}/${month}/${year}`;
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedDate,
        Age: age,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const storedDate = formData.DateOfBirth
    ? (() => {
        const dateString = formData.DateOfBirth;

        // Check if the date is in dd/mm/yyyy or dd-mm-yyyy
        let parts;
        if (dateString.includes("/")) {
          // Format: dd/mm/yyyy
          parts = dateString.split("/");
        } else if (dateString.includes("-")) {
          // Format: dd-mm-yyyy
          parts = dateString.split("-");
        } else {
          // If the format doesn't match, return an empty string or handle error
          return "";
        }

        // Return in yyyy-mm-dd format
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
      })()
    : "";

  const handleInputChange = (eventOrOption, name) => {
    let updatedData = { ...formData };

    if (eventOrOption.target) {
      // For standard input fields (like Rashi, PlaceOfBirth, TimeOfBirth)
      updatedData[name] = eventOrOption.target.value;
    } else {
      // For select options (Country, State, etc.)
      const selectedOption = eventOrOption;
    

      switch (name) {
        case "Country":
          updatedData.Country = selectedOption.value;
          updatedData.State = "";
          updatedData.City = "";
          setStates(State.getStatesOfCountry(selectedOption.value));
          setcities([]);
          break;

        case "State":
          updatedData.State = selectedOption.value;
          updatedData.City = "";
          setcities(
            City.getCitiesOfState(updatedData.Country, selectedOption.value)
          );
          break;

        case "Education":
          updatedData.Education = selectedOption.value;
          setCustomeInput({ CustomEdu: selectedOption.value === "Other" });
          break;

        default:
          updatedData[name] = selectedOption.value;
          break;
      }
    }

    setFormData(updatedData);
  };


  const handleSave = (formData) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      id: profiledataID,
      image: selectedFiles,
      data: formData,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/api/users/upme", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  const handleSendOtp = async () => {
    var custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };
    try {
      const response = await axios.post(
        `/api/auth/sendOtpVerification`,
        {
          value: formData.Mobile,
        },
        {
          headers: custom_headers,
        }
      );
      if (response.data.success) {
        setOtpSent(true);
        setShowOtpSection(true);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(`/api/auth/check-otp`, {
        Mobile: formData.Mobile,
        otp: formData.otp,
      });

      if (response.data.success) {
        setOtpVerified(true);
        setShowOtpSection(false);
        setOtpSent(true);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = { ...formData };

    // Remove fields if they are empty
    if (!dataToSend.Mobile) {
      delete dataToSend.Mobile;
    }
    if (!dataToSend.otp) {
      delete dataToSend.otp;
    }

    const custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };

    try {
      const response = await axios.post(
        `/api/profile/profiledetails`,
        dataToSend,
        {
          headers: custom_headers,
        }
      );
      if (response.data.message) {
        setFormData({
          Name: "",
          Age: "",
          height: "",
          weight: "",
          Education: "",
          ["Salary-PA_Standardized"]: "",
          Country: "",
          State: "",
          City: "",
          gender: "",
          MaritalStatus: "",
          MotherTongue: "",
          livingWithFamily: "",
          Denomination: "",
          religion: "",
          Cast: "",
          lookingFor: "",
          Mobile: "",
          otp: "",
          AboutMe: "",
          Prefering: "",
          AboutFamily: "",
          Profession: "",
          DateOfBirth: "",
          Rashi: "",
          PlaceOfBirth: "",
          TimeOfBirth: "",
        });

        if (response.data.details.personalData) {
          localStorage.setItem(
            "personal",
            JSON.stringify(response.data.details)
          );
          localStorage.setItem("personalDetails", "true");
        }

        {
          props.view
            ? toast.success("Profile Updated successfully!")
            : toast.success("Profile created successfully!");
        }

        setTimeout(() => {
          navigate("/subscribe-plan"); // Navigate to the success page or any other route
        }, 2000);
      }
    } catch (error) {
      console.error("Error creating profile:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const method = CookieService.getCookie("SignInMethod");
  console.log(formData, "formData.height");
  return (
    <div className="profile-create-bg">
      <div className="profile-container">
        {props.view ? (
          <h2 className="profile-heading">My Profile</h2>
        ) : (
          <h2 className="profile-heading">Register</h2>
        )}

        <div className="uploadpic">
          <div className="profile-upload">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {images && images.length > 0 ? (
                <>
                  <img
                    className="img-back"
                    src={`data:image/jpeg;base64,${images[0].imageData}`}
                    alt={`Profile ${images._id} image 1`}
                  />
                </>
              ) : (
                <img src={require("../../images/OIP.jpeg")} />
              )}

              {props.view ? (
                <>
                  <p className="jioid-profile"> JIO_ID: {formData.JIOID}</p>
                  <Link
                    to="/Add_Edit_Photos"
                    style={{ textDecoration: "none" }}
                  >
                    <p style={{ textAlign: "center" }} className="add-edit">
                      Add/Edit Photos
                    </p>
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>

            {props.view ? (
              ""
            ) : (
              <label className="custom-file-upload">
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  name="image"
                  onChange={handleChange}
                />
                <MdComputer className="imagegiv" />
                Upload Profile Pic
              </label>
            )}
          </div>
        </div>

        <form className="profile-form" onSubmit={handleSubmit}>
          {method === "google" && (
            <>
              <div>
                <label htmlFor="number" className="profile-form-label">
                  Mobile Number
                </label>
                {otpVerified ? <i className="fas fa-check"></i> : ""}

                <input
                  type="tel"
                  id="number"
                  name="Mobile"
                  value={formData.Mobile}
                  onChange={handleChange}
                  className="profile-form-input"
                  required
                />

                {!otpSent && !props.view && (
                  <button
                    type="button"
                    onClick={handleSendOtp}
                    className="profile-submit-button"
                  >
                    Send OTP
                  </button>
                )}
              </div>

              {showOtpSection && (
                <div>
                  <label htmlFor="otp" className="profile-form-label">
                    OTP
                  </label>
                  <input
                    type="text"
                    id="otp"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                    className="profile-form-input"
                    required
                  />
                  <button
                    type="button"
                    onClick={handleVerifyOtp}
                    className="profile-submit-button"
                  >
                    Verify OTP
                  </button>
                </div>
              )}
            </>
          )}
          {/* first name*/}
          <div>
            <label htmlFor="Firstname" className="profile-form-label">
              Name
            </label>
            <input
              type="text"
              id="Firstname"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* dob */}
          <div>
            <label htmlFor="dob">Date of Birth:</label>
            <input
              value={storedDate}
              type="date"
              id="dob"
              name="DateOfBirth"
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* Age Input */}
          <div>
            <label htmlFor="age" className="profile-form-label">
              Age
            </label>
            <input
              type="number"
              id="age"
              name="Age"
              value={formData.Age}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* Height Dropdown */}
          <div>
            <label htmlFor="height" className="profile-form-label">
              Height
            </label>
            <Select
              value={
                formData.height
                  ? { value: formData.height, label: `${formData.height}` }
                  : null
              } // Format value
              options={generateHeightOptions().map((val) => ({
                value: val,
                label: `${val}`, // Format the label
              }))}
              onChange={(selectedOption) =>
                handleInputChange(selectedOption, "height")
              }
              placeholder="Select"
            />
          </div>
          {/* Weight Dropdown */}
          <div>
            <label htmlFor="weight" className="profile-form-label">
              Weight
            </label>
            <input
              type="number"
              id="weight"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              className="profile-form-input"
              required
            />
          </div>
          {/* Educational Details Dropdown */}
          <div>
            <label htmlFor="educationalDetails" className="profile-form-label">
              Educational Details
            </label>
            <Select
              value={
                formData.Education
                  ? {
                      value: formData.Education,
                      label: `${formData.Education}`,
                    }
                  : null
              }
              options={educationData.map((val) => ({
                value: val,
                label: val,
              }))}
              onChange={(selectedOption) =>
                handleInputChange(selectedOption, "Education")
              }
              placeholder="Select"
            />
          </div>
          {CustomInput.CustomEdu ? (
            <div>
              <input
                placeholder="Enter your Education here"
                type="text"
                id="customedu"
                name="customEdu"
                value={formData.customEdu} // Use `CustomEdu` to match the state
                onChange={(event) => handleInputChange(event, "customEdu")} // Pass the event
                className="profile-form-input"
                required
              />
            </div>
          ) : (
            ""
          )}
          {/*profession  */}
          <div>
            <label htmlFor="profession" className="profile-form-label">
              Profession
            </label>
            <Select
              value={
                formData.Profession
                  ? {
                      value: formData.Profession,
                      label: `${formData.Profession}`,
                    }
                  : null
              }
              options={professionData.map((val) => ({
                value: val,
                label: val,
              }))}
              onChange={(selectedOption) =>
                handleInputChange(selectedOption, "Profession")
              }
              placeholder="Select"
            />
          </div>
          {/* Salary Input */}
          <div>
            <label htmlFor="Salary-PA_Standardized">Salary</label>
            <select
              id="salary"
              name="Salary-PA_Standardized"
              onChange={handleChange}
              value={formData["Salary-PA_Standardized"]}
              className="profile-form-input"
            >
              <option>Select</option>
              {salaryOptions.map((salary, index) => (
                <option key={index} value={salary}>
                  {salary}
                </option>
              ))}
            </select>
          </div>
          {/* Country Input */}
          <div>
            <label htmlFor="country" className="profile-form-label">
              Country
            </label>
            <Select
              value={
                formData.Country
                  ? {
                      value: formData.Country,
                      label: `${formData.Country}`,
                    }
                  : null
              }
              options={countries.map((country) => ({
                value: country.isoCode,
                label: country.name,
              }))}
              onChange={(selectedOption) =>
                handleInputChange(selectedOption, "Country")
              }
            />
          </div>
          {/* State Input */}
          <div>
            <label htmlFor="state" className="profile-form-label">
              State
            </label>
            <Select
              value={
                formData.State
                  ? {
                      value: formData.State,
                      label: `${formData.State}`,
                    }
                  : null
              }
              options={states.map((state) => ({
                value: state.isoCode,
                label: state.name,
              }))}
              onChange={(selectedOption) =>
                handleInputChange(selectedOption, "State")
              }
            />
          </div>
          {/* City Input */}
          <div>
            <label htmlFor="city" className="profile-form-label">
              City
            </label>
            <Select
              value={
                formData.City
                  ? {
                      value: formData.City,
                      label: `${formData.City}`,
                    }
                  : null
              }
              options={cities.map((city) => ({
                value: city.name,
                label: city.name,
              }))}
              onChange={(selectedOption) =>
                handleInputChange(selectedOption, "City")
              }
            />
          </div>
          {/* Gender Dropdown */}
          <div>
            <label htmlFor="Gender" className="profile-form-label">
              Gender
            </label>
            <select
              id="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className="profile-form-select"
              required
            >
              <option value="" disabled>
                Select one
              </option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
            </select>
          </div>
          {/* Marital Status Dropdown */}
          <div>
            <label htmlFor="maritalStatus" className="profile-form-label">
              Marital Status
            </label>
            <select
              id="maritalStatus"
              name="MaritalStatus"
              value={formData.MaritalStatus}
              onChange={handleChange}
              className="profile-form-select"
              required
            >
              <option value="" disabled>
                Select one
              </option>
              <option value="Unmarried">Unmarried</option>
              <option value="Divorced">Divorced</option>

              <option value="Widowed">Widowed</option>
            </select>
          </div>
          {/* Mother Tongue Input */}

          <div>
            <label htmlFor="motherTongue" className="profile-form-label">
              Mother Tongue
            </label>

            <Select
              value={
                formData.MotherTongue
                  ? {
                      value: formData.MotherTongue,
                      label: `${formData.MotherTongue}`,
                    }
                  : null
              }
              options={motherTon.map((val) => ({
                value: val,
                label: val,
              }))}
              onChange={(selectedOption) =>
                handleInputChange(selectedOption, "MotherTongue")
              }
              placeholder="Select"
            />
          </div>
          {/* Living with Family Dropdown */}
          <div>
            <label htmlFor="livingWithFamily" className="profile-form-label">
              Living with your family
            </label>
            <select
              id="livingWithFamily"
              name="livingWithFamily"
              value={formData.livingWithFamily}
              onChange={handleChange}
              className="profile-form-select"
              required
            >
              <option value="" disabled>
                Select one
              </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div>
            <label htmlFor="religion" className="profile-form-label">
              Religion
            </label>
            <Select
              id="religion"
              value={
                formData.religion // Use 'Cast' here
                  ? {
                      value: formData.religion, // Use 'Cast' here
                      label: formData.religion,
                    }
                  : null
              }
              options={religions.map((religion) => ({
                value: religion.name,
                label: religion.name,
              }))}
              onChange={handleReligionChange}
              placeholder="Select Religion"
            />
          </div>

          {/* Cast Input */}
          <div>
            <label htmlFor="cast" className="profile-form-label">
              {selectedCasteAlias}
            </label>
            <Select
              id="cast"
              value={
                formData.Cast // Use 'Cast' here
                  ? {
                      value: formData.Cast, // Use 'Cast' here
                      label: formData.Cast,
                    }
                  : null
              }
              options={casteOptions.map((caste) => ({
                // 'casteOptions' should still map to caste data
                value: caste.name,
                label: caste.name,
              }))}
              onChange={handleCasteChange}
              placeholder="Select Caste"
            />
          </div>
          {/* denomination Input */}
          <div>
            <label htmlFor="denomination" className="profile-form-label">
              sub-{selectedCasteAlias}
            </label>{" "}
            <Select
              value={
                formData.Denomination
                  ? {
                      value: formData.Denomination,
                      label: `${formData.Denomination}`,
                    }
                  : null
              }
              options={subCaste.map((val) => ({
                value: val,
                label: val,
              }))}
              onChange={(selectedOption) =>
                handleInputChange(selectedOption, "Denomination")
              }
              placeholder="Select"
            />
          </div>

          {formData.religion === "Hindu" && (
            <>
              <div>
                <label htmlFor="Rashi" className="profile-form-label">
                  Rashi
                </label>

                <Select
                  value={
                    formData.Rashi
                      ? { value: formData.Rashi, label: `${formData.Rashi}` }
                      : null
                  } // Format value
                  options={raasi.map((val) => ({
                    value: val,
                    label: `${val}`, // Format the label
                  }))}
                  onChange={(selectedOption) =>
                    handleInputChange(selectedOption, "Rashi")
                  }
                  placeholder="Select"
                />
              </div>

              <div>
                <label htmlFor="PlaceOfBirth" className="profile-form-label">
                  Place of Birth
                </label>
                <input
                  type="text"
                  id="PlaceOfBirth"
                  name="PlaceOfBirth"
                  value={formData.PlaceOfBirth}
                  onChange={(event) => handleInputChange(event, "PlaceOfBirth")}
                  className="profile-form-input"
                />
              </div>

              <div>
                <label htmlFor="TimeOfBirth" className="profile-form-label">
                  Time of Birth
                </label>
                <input
                  type="time"
                  id="TimeOfBirth"
                  name="TimeOfBirth"
                  value={formData.TimeOfBirth}
                  onChange={(event) => handleInputChange(event, "TimeOfBirth")}
                  className="profile-form-input"
                />
              </div>
            </>
          )}
          {/* Looking For Dropdown */}
          <div>
            <label htmlFor="lookingFor" className="profile-form-label">
              Whom you are looking for
            </label>
            <select
              id="lookingFor"
              name="lookingFor"
              value={formData.lookingFor}
              onChange={handleChange}
              className="profile-form-select"
              required
              disabled={formData.gender && formData.lookingFor !== ""}
            >
              <option value="" disabled>
                Select one
              </option>
              <option value="Bride">Bride</option>
              <option value="Bridegroom">Bridegroom</option>
            </select>
          </div>
          {/* About family */}
          <div>
            <label htmlFor="Aboutfamily" className="profile-form-label">
              About Family
            </label>
            <textarea
              name="AboutFamily"
              id="Aboutfamily"
              placeholder="Tell us about your family..."
              rows="5"
              cols="50"
              maxlength="500"
              onChange={handleChange}
              className="profile-form-input"
              value={formData.AboutFamily}
            ></textarea>
          </div>
          {/* About You */}
          <div>
            <label htmlFor="lookingFor" className="profile-form-label">
              About You
            </label>
            <textarea
              name="AboutMe"
              placeholder="Tell us about yourself..."
              rows="5"
              cols="50"
              maxlength="500"
              onChange={handleChange}
              className="profile-form-input"
              value={formData.AboutMe}
            ></textarea>
          </div>
          <div>
            <label htmlFor="lookingFor" className="profile-form-label">
              Prefrences
            </label>
            <textarea
              name="Prefering"
              placeholder="Tell Your preferences that you want in your partner..."
              rows="5"
              cols="50"
              maxlength="500"
              onChange={handleChange}
              className="profile-form-input"
              value={formData.Prefering}
            ></textarea>
          </div>
          {props.view ? (
            <div>
              <p className="plan-limit">
                You have viewed{" "}
                <span style={{ fontWeight: "bold" }}>
                  {formData.contactShowLimit}
                </span>{" "}
                contacts. You can still view{" "}
                <span style={{ fontWeight: "bold" }}>
                  {formData.membership_status === "Silver"
                    ? 45 - formData.contactShowLimit
                    : formData.membership_status === "Gold"
                    ? 75 - formData.contactShowLimit
                    : formData.membership_status === "Platinum"
                    ? 150 - formData.contactShowLimit
                    : formData.membership_status === "Unpaid"
                    ? 5 - formData.contactShowLimit
                    : formData.membership_status === "Expired"
                    ? 10 - formData.contactShowLimit
                    : 0 - formData.contactShowLimit}
                </span>{" "}
                more contacts before reaching your limit.
              </p>
            </div>
          ) : (
            ""
          )}
          {!props.view ? (
            <button type="submit" className="profile-submit-button">
              Create Profile
            </button>
          ) : (
            <button type="submit" className="profile-submit-button">
              Update Profile
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateProfile;
