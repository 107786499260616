import "./App.css";
import React, { useState, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "./components/common/Navbar.js";
import HomePage from "./components/Home/Home.js";
import Footer from "./components/common/Footer.js";
import LoginPage from "./components/signin-out/LoginPage.js";
import SignUpPage from "./components/signin-out/SignUpPage.js";
import FreeQuoteForm from "./components/Home/FreeQuoteForm.js";
import Modal from "./components/Home/Modal.js";
import whatsapp from "./images/whatsapp-v1.png";
import ForgotPasswordPage from "./components/signin-out/forgotpassword.js";
import ResetPassword from "./components/signin-out/resetpassword.js";
import Filteredlist from "./components/Dashboard/filteredlist.js";

import CanvasUpscaledImage from "./components/image.js";
import Subscribe from "./components/subscribe.js";

import CreateProfile from "./components/signin-out/profilecreating.js";

import PaymentPage from "./components/payment/PymentGate.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { UserContext, UserProvider } from "./context/UserContext";
import Landing from "./components/landingpage.js";
import LandingNavbar from "./components/common/LandingNavbar.js";
import CookieService from "encrypted-cookie";
import ScrollToTop from "./components/scrollTop.js";
import ProfileId from "./components/Dashboard/profileId.js";
import PrivacyPolicy from "./components/common/Privacy_Policy.js";
import Terms_Condition from "./components/common/Terms_Condition.js";
import Refund_Policies from "./components/common/Refund_Policies.js";
import ScrollToTopButton from "./components/scrollBar.js";
import { ToastContainer } from "react-toastify";
import AllUsers from "./components/Dashboard/allusers.js";

import "react-toastify/dist/ReactToastify.css";
import NotFound from "./components/notfound.js";

import AddUploadImg from "./components/Dashboard/UploadImg.js";
import Help from "./components/help/help.js";
import UserDetail from "./components/Home/UserDetails.js";
import ProtectedRoute from "./protectedRoutes.js";
import RegisterForm from "./components/signin-out/SignUpPage.js";
import FavoritesList from "./components/Home/FavoritesList.js";

function App() {
  const [showFixedButtons, setShowFixedButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  // popup msg
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    if (
      location.pathname === "/dashboard" ||
      location.pathname === "/Register"
    ) {
      setOpenPopup(true);
    } else {
      setOpenPopup(false);
    }
  }, [location]);
  // Handle closing the popup
  const closePopup = () => {
    setOpenPopup(false);
  };
  useEffect(() => {
    const token = CookieService.checkCookie("token");

    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowFixedButtons(true);
      } else {
        setShowFixedButtons(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleQuoteClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <UserProvider>
      <div className="App">
        {location.pathname === "/" ? <LandingNavbar /> : <Navbar />}
        <GoogleOAuthProvider clientId="389834265463-bt4aon9p21jdqjbiqrlvhfcuov5g0m8l.apps.googleusercontent.com">
          <ScrollToTop />
          <ToastContainer />
          <ScrollToTopButton />

          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/signin" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage data={true} />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/forgot-password/:token" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<Terms_Condition />} />
            <Route path="/refund-policy" element={<Refund_Policies />} />
            <Route path="/filtered-list" element={<Filteredlist />} />
            <Route path="/subscribe-plan" element={<Subscribe />} />
            <Route path="/profileid" element={<ProfileId />} />
            <Route
              path="/subscribe-plan/PaymentGate"
              element={<PaymentPage />}
            />
            <Route path="/Register" element={<CreateProfile />} />

            <Route element={<ProtectedRoute />}>
              <Route
                path="/dashboard"
                element={<HomePage showPopup={true} />}
              />
              <Route path="/help" element={<Help />} />
              <Route
                path="/my-profile"
                element={<CreateProfile view={true} />}
              />
              <Route path="/Add_Edit_Photos" element={<AddUploadImg />} />
              <Route path="/get-all-users" element={<AllUsers />} />
              <Route path="/user/:jioId" element={<UserDetail />} />
              <Route path="/favorites" element={<FavoritesList />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </GoogleOAuthProvider>
        <Footer />
        {showFixedButtons && (
          <div className="fixed-buttons">
            <a
              href="https://wa.me/+918885073555?text=Hello, I would like to get more information about your services."
              className="whatsapp-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp} alt="WhatsApp" className="whats" /> Chat with
              us
            </a>
            <a className="quote-button" onClick={handleQuoteClick}>
              Get Free Quote
            </a>
          </div>
        )}

        <Modal show={showModal} onClose={handleCloseModal}>
          <FreeQuoteForm />
        </Modal>

        {/* Modal for Popup Message */}
        {openPopup && (
          <Modal show={openPopup} onClose={closePopup} className="dashmsg">
            <div className="popup-message">
              <h2>Discover Your Perfect Match!</h2>
              <p>
                Explore over 20,000+ Rich Profiles – Where Love Meets
                Commitment!
              </p>
            </div>
          </Modal>
        )}
      </div>
    </UserProvider>
  );
}

export default App;
