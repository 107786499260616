import React, { useState, useEffect } from "react";
import "../styles/landingpage.css";
import WhyAreWeBetter from "./Home/WhyAreWeBetter";
import RegisterForm from "./signin-out/SignUpPage";

const Landing = () => {
  return (
    <div>
      <div className="Landing-page">
        {/* <div>
          <img src={require("../images/im1.jpeg")} />
        </div>
        <div>
          <img src={require("../images/im2.jpeg")} />
        </div>
        <div>
          <img src={require("../images/im1.jpeg")} />
        </div>
        <div>
          <img src={require("../images/im2.jpeg")} />
        </div>
        <div>
          <img src={require("../images/im1.jpeg")} />
        </div>
        <div>
          <img src={require("../images/im2.jpeg")} />
        </div>
        <div>
          <img src={require("../images/im1.jpeg")} />
        </div>
        <div>
          <img src={require("../images/im2.jpeg")} />
        </div> */}
        <RegisterForm data={false} />
      </div>
      <WhyAreWeBetter landing="true" />
    </div>
  );
};
export default Landing;
