import "../../styles/Navbar.css";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import CookieService from "encrypted-cookie";
import { GiQueenCrown } from "react-icons/gi";
import { FaXmark } from "react-icons/fa6";

const LandingNavbar = () => {
  return (
    <nav className="navigation landing-nav">
      <a href="/" className="brand-name">
        <img src={require("../../images/JIOMLOGO.jpg")} />
      </a>

      <ul className="signin-way">
        <li>Already a member ? </li>
        <li>
          <Link to="/signin" className="button-login">
            LOG IN
          </Link>
        </li>
        <li>
          
          <p>
            <a href="/help">Help ?</a>
          </p>
        </li>
      </ul>
    </nav>
  );
};

export default LandingNavbar;
