import React, { useState } from "react";
import "../../styles/FreeQuoteForm.css";
import ConfettiExplosion from "react-confetti-explosion"

const FreeQuoteForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    details: ""
  });

  const [submitted, setSubmitted] = useState(false);
  const [isExploding, setExploding] = useState(false)

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setExploding(true)
  };

  return (
    <div className="free-quote-form">
      <h2>Get Free Quote</h2>
      {submitted ? (
        <div className="submitMessage">
          <p>
            Dear <span style={{ "fontSize": "20px" }}> {formData.name},</span> <br />
            Thank you for reaching out! Our team member is reviewing your request and will get in touch with you shortly.
          </p>
          {isExploding && (
            <ConfettiExplosion
              force={0.4}
              duration={2200}
              particleCount={30}
              width={400}
              zIndex={10000}
            />
          )}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="details">Details</label>
            <textarea
              id="details"
              name="details"
              value={formData.details}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default FreeQuoteForm;
