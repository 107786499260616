import React, { useEffect, useState } from "react";
import axios from "axios";
import CookieService from "encrypted-cookie";
import defaultAvatar from "../../images/OIP.jpeg"; 
import "../../styles/FavoritesList.css"; 
import AOS from 'aos'; 
import 'aos/dist/aos.css'; 
import { MdFavorite, MdFavoriteBorder } from "react-icons/md"; 

const FavoritesList = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    AOS.init(); // Initialize AOS
    const fetchFavorites = async () => {
      const token = CookieService.getCookie("token");
      const headers = { Authorization: token };
      const personalDataString = localStorage.getItem("personal");
      const personalData = JSON.parse(personalDataString);
      const viewerJioId = personalData.JIOID;

      try {
        const response = await axios.get(`/api/users/getFavorites`, {
          headers,
          params: { userId: viewerJioId },
        });

        const favoriteIds = response.data.favorites;

        const userDetailsPromises = favoriteIds.map((id) =>
          axios.get(`/api/users/GetUsersById`, {
            params: { id }, 
            headers,
          })
        );

        const userDetailsResponses = await Promise.all(userDetailsPromises);
        
        const users = userDetailsResponses.map((res) => res.data.users[0]); 
        console.log(users);
        
        setFavorites(users); 
      } catch (err) {
        console.error("Error fetching favorites:", err);
        setError(err.response?.data?.error || "Failed to fetch favorites.");
      } finally {
        setLoading(false);
      }
    };

    fetchFavorites();
  }, []);

  const toggleFavorite = async (userId) => {
    const personalDataString = localStorage.getItem("personal");
    const personalData = JSON.parse(personalDataString);
    const loggedInUserId = personalData.JIOID;

    const token = CookieService.getCookie("token");
    const headers = { Authorization: token };

    try {
      if (favorites.some(user => user.JIOID === userId)) {
        // If the user is already a favorite, remove them
        await axios.post('/api/users/removeFavorite', {
          userId: loggedInUserId,
          favoriteJioId: userId
        }, { headers });
        
        // Update the local state to remove the user from favorites
        setFavorites(favorites.filter(user => user.JIOID !== userId));
      } else {
        // If the user is not a favorite, add them
        await axios.post('/api/users/addFavorite', {
          userId: loggedInUserId,
          favoriteJioId: userId
        }, { headers });

        // Update the local state to add the user to favorites
        const updatedUser = await axios.get(`/api/users/GetUsersById`, {
          params: { id: userId }, 
          headers,
        });

        setFavorites([...favorites, updatedUser.data.users[0]]);
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="favorites-list-container">
      <h2 className="favorites-heading">My Favorite List</h2>
      <div className="favorites-list">
        {favorites.length > 0 ? (
          favorites.map((user) => (
            <div 
              key={user.JIOID} 
              className="favorite-card" 
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="favorite-image">
                {user.images && user.images.length > 0 ? (
                  <img
                    src={`data:image/jpeg;base64,${user.images[0]}`}
                    alt={`Favorite User ${user.JIOID}`}
                  />
                ) : (
                  <img
                    src={defaultAvatar}
                    alt="Default Avatar"
                    className="default-avatar"
                  />
                )}
              </div>
              <div className="favorite-details">
                <h3>{user.Name}</h3>
                <p><strong>JIO ID:</strong> J{user.JIOID}</p>
                <p><strong>Age:</strong> {user.Age || "Not Specified"}</p>
                <p><strong>Height:</strong> {user.height || "Not Specified"}</p>
              </div>
              <div className="favorite-icon" onClick={() => toggleFavorite(user.JIOID)}>
                  {favorites.some(u => u.JIOID === user.JIOID) ? (
                    <MdFavorite color="red" size={24} />
                  ) : (
                    <MdFavoriteBorder size={24} />
                  )}
                </div>
            </div>
          ))
        ) : (
          <div>No favorites found.</div>
        )}
      </div>
    </div>
  );
};

export default FavoritesList;
