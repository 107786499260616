import React, { useState } from "react";
import "../../src/styles/subscribe.css";
import { SlArrowRight } from "react-icons/sl";
import { useNavigate } from "react-router";
import { FaXmark } from "react-icons/fa6";
import { PiCurrencyInr } from "react-icons/pi";
import { GiPartyPopper } from "react-icons/gi";
import { SiRazorpay } from "react-icons/si";


const PricingPlan = ({
  title,
  price,
  duration,
  contactsCount,
  features,
  bestValue,
  onContinue,
  onSelectPlan,
}) => {
  return (
    <div
      className={`pricing-plan ${bestValue ? "best-value" : ""}`}
      onClick={() => onSelectPlan(price, title)}
    >
      <h3>{title}</h3>
      <h2>₹{price}</h2>
      <p>{duration}</p>
     
      <ul>
        <li className="contacts">Accesss to  {contactsCount}  Contacts</li>
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <button onClick={onContinue}>Continue</button>
      <button className="Razorpay" > <SiRazorpay /> Razorpay</button>
    </div>
  );
};

const Subscribe = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [addExtraContacts, setAddExtraContacts] = useState(false);
  const [promoteProfile, setPromoteProfile] = useState(false);

  const plans = [
    {
      title: "Silver Pack",
      price: 3000,
      duration: "3 Months",
      contactsCount: " 45 ",
      features: [
        "SMS Campaign",
        "WhatsApp Campaign",
        "Sending the Suitable Profiles List",
        "YouTube Display",
      ],
      bestValue: false,
    },
    {
      title: "Gold Pack",
      price: 5000,
      duration: "6 Months",
      contactsCount: "75 ",
      features: [
        "SMS Campaign",
        "WhatsApp Campaign",
        "Sending the Suitable Profiles List",
        "YouTube Display",
        "Direct Calling to Suitable Selected Profiles",
        "Updation of Feedback from Our Team Membership Fees: Rs 5000",
      ],
      bestValue: false,
    },
    {
      title: "Platinum Pack",
      price: 10000,
      duration: "1 Year",
      contactsCount: " 150 ",
      features: [
        "SMS Campaign",
        "WhatsApp Campaign",
        "Sending the Suitable Profiles List",
        "YouTube Display",
        "Direct Calling to Suitable Selected Profiles",
        "Updation of Feedback from Our Team",
        "Conducting Meetings at Our Office or Coordination of Meetings Membership Fees: Rs 10000",
      ],
      bestValue: true,
    },
  ];

  const handlePay = () => {
    navigate("/subscribe-plan/PaymentGate");
  };

  const handleBack = () => {
    navigate("/dashboard");
  };

  const handleContinue = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSelectPlan = (price, title) => {
    setSelectedPlan({ price, title });
  };

  // Calculate the total amount based on selected plan and additional options
  const calculateDiscount = () => {
    if (!selectedPlan) return 0;
    return selectedPlan.price * 0.3; // Example: 30% discount
  };

  const calculateTotalAmount = () => {
    if (!selectedPlan) return 0;

    let total = selectedPlan.price;
    let discount = calculateDiscount();

    if (addExtraContacts) total += 255;
    if (promoteProfile) total += 339;

    return total - discount; // Subtract discount from the total
  };

  return (
    <div className="Subscription">
      <header className="Subscription-header">
        <h1>Upgrade now & Get up to 50% discount!</h1>
        <p className="para">
          Save up to 50% on Premium Plans! Valid for a limited period!
        </p>
        <p className="later">
          <span onClick={handleBack}>
            Do this later <SlArrowRight />
          </span>
        </p>

        <div className="pricing-plans">
          {plans.map((plan, index) => (
            <PricingPlan
              key={index}
              {...plan}
              onContinue={handleContinue}
              onSelectPlan={handleSelectPlan}
            />
          ))}
        </div>
      </header>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-top">
              <FaXmark
                size={20}
                color="black"
                className="xmark-popup"
                onClick={handleClosePopup}
              />
              <h1>Order Summary</h1>
            </div>
            <div className="order-summary">
              {selectedPlan && (
                <ul>
                  <li>
                    <p>{selectedPlan.title} (3 Months)</p>
                    <span>
                      <PiCurrencyInr />
                      {selectedPlan.price}
                    </span>
                  </li>
                  <li>
                    <p>Saving (30% off)</p>
                    <span style={{ color: "#03534fbf", fontWeight: "500" }}>
                      -<PiCurrencyInr />
                      {calculateDiscount()}
                    </span>
                  </li>
                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="check1"
                        checked={addExtraContacts}
                        onChange={() => setAddExtraContacts(!addExtraContacts)}
                      />
                      <label htmlFor="check1">Add 50 extra Contact nos</label>
                    </div>
                    <span>
                      <PiCurrencyInr />
                      {addExtraContacts ? 255 : 0}
                    </span>
                  </li>
                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="check2"
                        checked={promoteProfile}
                        onChange={() => setPromoteProfile(!promoteProfile)}
                      />
                      <label htmlFor="check2">Promote my profile</label>
                    </div>
                    <span>
                      <PiCurrencyInr />
                      {promoteProfile ? 339 : 0}
                    </span>
                  </li>
                  <hr />
                  <li>
                    <p>Total</p>
                    <span>
                      <PiCurrencyInr />
                      {calculateTotalAmount()}
                    </span>
                  </li>
                  <hr />
                </ul>
              )}
              <div className="Congrats-text">
                <p>
                  <GiPartyPopper className="popper" /> You saved{" "}
                  <PiCurrencyInr /> {calculateDiscount()} on this order{" "}
                  <GiPartyPopper className="popper" />
                </p>
              </div>

              <button onClick={handlePay}>Proceed to Pay</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscribe;
