import React, { useState } from "react";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";

const faqData = [
  {
    question: "How can I find my match quickly?",
    answer: (
      <ul>
        <li>
          Login regularly to see the new matches and suggestions.
          <a href="/signin" target="_blank">Login now</a>,
        </li>
        <li>
          Have a complete profile with your current career details, education,
          and profile picture/video.
          <a href="my-profile" target="_blank">Update your profile now</a>.
        </li>
        <li>
          Become a premium member to stay on top of search results.
          <a href="/subscribe-plan" target="_blank">Know more about Premium plans</a>.
        </li>
      </ul>
    ),
  },
  {
    question: "How fast can I find my match?",
    answer: (
      <p>
        We hope that you find your perfect partner at the earliest. However,
        finding a life partner is an important decision. Take your time, understand
        your matches, and make the best decision. If you're lost, a VIP package
        offers a relationship manager to assist you in your search.
      </p>
    ),
  },
  {
    question: "How can I create an account on Jiomatrimony.com?",
    answer: (
      <p>
        It's simple. <a href="/signup">Sign up Now</a> to create your profile.
        Enter your details and verify your profile via email and phone. After a
        verification call from our agent, your account will be active.
      </p>
    ),
  },
  {
    question: "Is my personal information safe and secure?",
    answer: (
      <p>
        Yes, we take your privacy seriously. Your personal information is encrypted and only shared with users you choose to connect with. We comply with all data protection regulations to ensure your safety and confidentiality.
      </p>
    ),
  },
  {
    question: "How can I search for suitable profiles?",
    answer: (
      <p>
        Use <a href="/profileid">Search by JIOID</a> or apply normal/advanced filters.
        Premium members can use advanced search for more specific preferences.
      </p>
    ),
  },
  {
    question: "Who should get a JioMatrimony Assist?",
    answer: (
      <ul>
        <li>You want to speed up your search for a partner.</li>
        <li>You have a busy life and lack time to browse new matches daily.</li>
        <li>Your parents are helping but may not be tech-savvy.</li>
      </ul>
    ),
  },
  {
    question: "Can I log into my profile from multiple devices simultaneously?",
    answer: <p>Yes, you can log in from multiple devices at the same time.</p>,
  },
  {
    question: "How do I log in if I forget my password?",
    answer: (
      <ul>
        <li>Click on <a href="/forgot-password">Forgot Password</a>.</li>
        <li>Enter your email associated with the account.</li>
        <li>You will receive an email/SMS to reset your password.</li>
      </ul>
    ),
  },
  {
    question: "Can I change the details associated with my matrimony account?",
    answer: (
      <ul>
        <li>Go to <a href="/my-profile">My Profile</a>.</li>
        <li>Follow the steps to update your details securely.</li>
      </ul>
    ),
  },
  {
    question: "How can I search for users easily if I know their name or JIOID?",
    answer: (
      <p>
        Navigate to <a href="/profileid">Search by JIOID</a>. If you know their JIOID or name, you can use the radio buttons to search by either identifier.
      </p>
    ),
  },
  {
    question: "What are Featured Profile Users?",
    answer: (
      <p>
        Featured Profile Users are selected based on specific criteria such as gender, height, age, and education. The top 14 profiles that best match these attributes are highlighted and displayed for better visibility.
      </p>
    ),
  },
  {
    question: "How do I get matched quickly with expert help?",
    answer: (
      <p>
        To find better matches faster, you can fill out the "Request Expert Call" form on JioMatrimony. A dedicated expert will assist you in your search for a compatible partner.
      </p>
    ),
  },
  {
    question: "How do I connect with a JioMatrimony representative on WhatsApp?",
    answer: (
      <p>
        To connect with a JioMatrimony representative on WhatsApp, simply click the "Chat with Us" button located at the bottom of the page. From there, you can send a message, and our team will assist you with your queries.
      </p>
    ),
  },
  {
    question: "How can I find another user's contact details?",
    answer: (
      <p>
        You can find another user's contact details by clicking on the "Show Contact Details" button. This feature also displays the expiration date of your membership plan and the remaining count limit for viewing contacts.
      </p>
    ),
  },
  {
    question: "How can I provide feedback about my experience?",
    answer: (
      <p>
        We value your feedback! You can provide feedback through the "Contact Us" section on our website, or you can directly message our customer support team. Your input helps us improve our services and enhance your experience.
      </p>
    ),
  },
];

function Contact() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleShow = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="contact-container">
      <p>
        JioMatrimony is excited to assist you in finding a mate as soon as
        possible. We will be happy to help you with any questions you may have
        at any time. We offer the following methods for you to get in touch with
        our customer support team.
      </p>
      <div className="contact-ways">
        <p>Helpline Number and Email</p>
        <div className="country-name">
          <img src={require("../../images/flag.png")} alt="India Flag" />
          <p>INDIA <span>91-7893206880</span></p>
        </div>

        <div className="country-name">
          <img src={require("../../images/email.webp")} alt="Email" />
          <p><span>contact@jiomatrimony.com</span></p>
        </div>

        <div style={{ borderBottom: "dotted 1px grey" }}></div>
        <p id="faq">Frequently Asked Questions</p>

        {faqData.map((item, index) => (
          <div key={index} className="each-item">
            <div className="each-card">
              <div className="each-question">
                <p><span>{index + 1}.</span> {item.question}</p>
                {activeIndex === index ? (
                  <SlArrowUp
                    onClick={() => toggleShow(index)}
                    className="icon-toshow"
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <SlArrowDown
                    onClick={() => toggleShow(index)}
                    className="icon-toshow"
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
              {activeIndex === index && (
                <div className="each-aws">{item.answer}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Contact;
