import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/WhyAreWeBetter.css";
import giftopen from "../../images/giftopen.jpg";
import giftclose from "../../images/giftclose.jpg";
import bulbon from "../../images/bulbon.jpg";
import bulboff from "../../images/bulboff.jpg";
import yoga from "../../images/yoga.jpg";
import axios from "axios";

const WhyAreWeBetter = ({ landing }) => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", phone: "" });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const benefits = [
    {
      icon: giftclose,
      hoverIcon: giftopen,
      title: "Exclusive Deals",
      description: "Best deals made only for you tailored to your preferences.",
      onClick: () => navigate("/subscribe-plan"),
    },
    {
      icon: bulboff,
      hoverIcon: bulbon,
      title: "Expert Insights",
      description: "Our wedding experts know how to craft the best for you.",
      onClick: () => setShowModal(true),
    },
    {
      icon: yoga,
      hoverIcon: yoga,
      title: "Stress-free Experience",
      description: "From venue recce to the last second of your wedding, we’ll be with you.",
      onClick: () => navigate("/help"),
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/users/reqestCall", formData);
      setMessage("Your request has been sent! Our expert will call you shortly.");

      setFormData({ name: "", email: "", phone: "" });
    } catch (error) {
      setMessage("Failed to send request. Please try again later.");
    }
  };

  return (
    <div className="why-we-are-better-sec">
      <section className={`why-are-we-better ${landing ? "landing-active" : ""}`}>
        <h2>What Makes Us Different</h2>
        <p>Because we bring our years of experience in planning your wedding.</p>
        <div className="benefits">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="benefit-card"
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
              onClick={benefit.onClick}
              style={{ cursor: benefit.onClick ? "pointer" : "default" }}
            >
              <img
                src={hoveredCard === index ? benefit.hoverIcon : benefit.icon}
                alt={benefit.title}
              />
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Modal for capturing user details */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Request Expert Call</h3>
            <p style={{ margin: "0", fontSize: "14px" }}>Please submit your details to get a call from our expert. </p>
            <p
              style={{ margin: "0", color: "black", fontSize: "14px", marginBottom: "10px" }}>
              Our team will guide you in finding your perfect match with personalized advice.
            </p>

            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Phone:
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <div className="mailsendbtns">
                <button className="line-shade" style={{ background: "#25D366" }} type="submit">Submit</button>
                <button className="line-shade" style={{ background: "#FF6F61" }} type="button" onClick={() => setShowModal(false)}>Close</button>
              </div>
            </form>
            {message && <p>{message}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default WhyAreWeBetter;
