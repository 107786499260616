import "../../styles/allusers.css";
import React, { useState, useEffect, useContext } from "react";
import "../../styles/Home.css";
import HowItWorks from "./HowItWorks.js";
import FeaturedProfiles from "./FeaturedProfiles.js";
import WhyAreWeBetter from "./WhyAreWeBetter.js";
import Testimonials from "./Testimonials.js";
import Cookies from "js-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Filteredlist from "../Dashboard/filteredlist.js";
import CookieService from "encrypted-cookie";
import Select, { components } from "react-select";

import { UserContext } from "../../context/UserContext.js"; // Adjust the path accordingly
import { Chip, MenuItem } from "@mui/material";
import CustomMultiSelectDropdown from "./checkbox.js";
import DropdownWithCheckbox from "./checkbox.js";

const Filteration = ({ setLoading, setCount }) => {
  const [education, setEducation] = useState([]);
  const [professions, setProfession] = useState([]);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const { errfil, setErrFil } = useContext(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [err, setError] = useState(null);
  const [userdata, setUserdata] = useState([]);
  const [filterdata, setFilterdata] = useState({
    ageFrom: 22,
    ageTo: 27,
    withImages: true,
    "Bride/Bridegroom": "",
    heightfrom: "",
    heightto: "",
    Denomination: [],
    Cast: [],
    Education: [],
    Profession: [],
    MaritalStatus: [],
    City: [],
    Country: [],
    salary: [],
  });

  const [religionOptions, setReligionOptions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const location = useLocation();

  const [gendergiv, setGenderGiv] = useState(["Bridegroom", "Bride"]);
  const [maritial, setMaritial] = useState([
    "Unmarried",
    "Divorced",

    "Widowed",
  ]);
  const [salaryOptions] = useState([
    "Below 2LPA",
    "2LPA - 5LPA",
    "5LPA - 10LPA",
    "10LPA - 15LPA",
    "15LPA - 20LPA",
    "20LPA - 25LPA",
    "25LPA - 30LPA",
    "30LPA - 35LPA",
    "35LPA - 40LPA",
    "40LPA - 45LPA",
    "45LPA - 50LPA",
    "50LPA - 55LPA",
    "55LPA - 60LPA",
    "60LPA - 65LPA",
    "65LPA - 70LPA",
    "70LPA - 75LPA",
    "75LPA - 80LPA",
    "80LPA - 85LPA",
    "85LPA - 90LPA",
    "90LPA - 95LPA",
    "95LPA - 1 Cr",
    "Above 1 Cr",
  ]);

  const navigate = useNavigate();
  const generateHeightOptions = () => {
    const heights = [];
    for (let feet = 4; feet <= 7; feet++) {
      for (let inches = 0; inches < 12; inches += 1) {
        let height = `${feet}ft ${inches}inch`;
        if (inches === 0) {
          height = `${feet}ft`;
        }
        heights.push(height);
      }
    }
    return heights;
  };

  useEffect(() => {
    const token = CookieService.checkCookie("token");
    setIsAuthenticated(!!token);

    const personalDataString = localStorage.getItem("personal");
    const personalData = personalDataString
      ? JSON.parse(personalDataString)
      : null;

    if (personalData) {
      setFilterdata((prevFilterData) => {
        const updatedFilterData = { ...prevFilterData }; // Create a copy of the current state

        if (personalData.lookingFor) {
          updatedFilterData["Bride/Bridegroom"] = personalData.lookingFor;

          if (personalData.lookingFor === "Bride") {
            updatedFilterData.ageFrom = personalData.Age - 5; // Default value if none is provided
            updatedFilterData.ageTo = personalData.Age; // Default value if none is provided
          } else if (personalData.lookingFor === "Bridegroom") {
            updatedFilterData.ageFrom = personalData.Age; // Default value if none is provided
            updatedFilterData.ageTo = personalData.Age + 5; // Default value if none is provided
          }

          if (personalData.lookingFor === "Bride" && personalData.height) {
            updatedFilterData.heightto = personalData.height;
          } else if (
            personalData.lookingFor === "Bridegroom" &&
            personalData.height
          ) {
            updatedFilterData.heightfrom = personalData.height;
          }
        } else if (personalData["Bride/Bridegroom"]) {
          updatedFilterData["Bride/Bridegroom"] =
            personalData["Bride/Bridegroom"] === "Bride"
              ? "Bridegroom"
              : "Bride";

          if (personalData["Bride/Bridegroom"] === "Bride") {
            updatedFilterData.ageFrom = personalData.Age; // Default value if none is provided
            updatedFilterData.ageTo = personalData.Age + 7;
          } else {
            updatedFilterData.ageFrom = personalData.Age - 7; // Default value if none is provided
            updatedFilterData.ageTo = personalData.Age;
          }

          if (personalData.height) {
            if (personalData["Bride/Bridegroom"] === "Bride") {
              updatedFilterData.heightfrom = personalData.height;
            } else {
              updatedFilterData.heightto = personalData.height;
            }
          }
        }

        return updatedFilterData; // Return the updated state
      });
    }
  }, [location]);
  console.log(filterdata, "fil");

  const Dropdown = async () => {
    try {
      const response = await fetch(`/api/admin/searchable-dropdown`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (response.ok) {
        var cities = [];
        var professionData = [];
        var communityData = [];
        var casteData = [];
        var educationData = [];
        var country = [];

        var religionData = [];
        var motherTongue = [];
        var subCasteData = [];
        var raasi = [];
        for (var x in result.data.raasiData) {
          raasi.push(result.data.raasiData[x].name);
        }
        for (var x in result.data.subCasteData) {
          subCasteData.push(result.data.subCasteData[x].name);
        }
        for (var x in result.data.motherTongue) {
          motherTongue.push(result.data.motherTongue[x].name);
        }
        for (var x in result.data.professionData) {
          professionData.push(result.data.professionData[x].name);
        }
        for (var x in result.data.religionData) {
          religionData.push(result.data.religionData[x].name);
        }
        for (var x in result.data.cities) {
          cities.push(result.data.cities[x].name);
        }
        for (var x in result.data.communityData) {
          communityData.push(result.data.communityData[x].name);
        }
        for (var x in result.data.casteData) {
          casteData.push(result.data.casteData[x].name);
        }
        for (var x in result.data.educationData) {
          educationData.push(result.data.educationData[x].name);
        }
        for (var x in result.data.country) {
          country.push(result.data.country[x].name);
        }

        setProfession(professionData);
        setCityOptions(cities);
        setReligionOptions(communityData);
        setCasteOptions(casteData);
        setEducation(educationData);

        setRegionOptions(country);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    Dropdown();
  }, []);

  const handleFilter = async () => {
    const personalDataString = localStorage.getItem("personal");
    const personalData = JSON.parse(personalDataString);

    if (personalData) {
      let genderParam = "";
      let heightCM = "";

      if (personalData.height && personalData.height !== "NA") {
        heightCM = personalData.height;
      }
      if (personalData.lookingFor) {
        genderParam = personalData.lookingFor;
      } else if (personalData["Bride/Bridegroom"]) {
        if (personalData["Bride/Bridegroom"] === "Bride") {
          genderParam = "Bridegroom";
        } else {
          genderParam = "Bride";
        }
      }

      if (!isAuthenticated) {
        setTimeout(() => {
          navigate("/signin");
        }, 1500);
        return;
      }
      var custom_headers = {
        "Content-Type": "application/json",
        Authorization: CookieService.getCookie("token"),
        gender: genderParam,
        height: heightCM,
      };
      try {
        setUserDetails([]);
        setLoading(true);
        setErrFil("");
        const response = await axios.get(`/api/users/usersdetails`, {
          params: filterdata,
          headers: custom_headers,
        });

        const data = response.data;
        console.log(data, "ddddd");
        if (data.message) {
          setLoading(false);
          setUserdata([]);
          setErrFil(data.message);
          setUserDetails([]);
        } else {
          setLoading(false);
          setErrFil("");
          setUserdata(data.users);
          setCount(data.count);
          setUserDetails((prevData) => [...prevData, ...data.users]);
        }
      } catch (error) {
        console.error("Error fetching filtered data:", error);
      }
    }
  };

  const handleInputSome = (selectedOption, name) => {
    setFilterdata({
      ...filterdata,
      [name]: selectedOption ? selectedOption.value : "", // Handle the possibility of no selection
    });
  };

  const handleInputChange = (selectedOptions, name) => {
    console.log(selectedOptions, "selectedOptions");
    if (selectedOptions) {
      const values = selectedOptions.map((option) => option);
      setFilterdata({
        ...filterdata,
        [name]: values,
      });
    } else {
      setFilterdata({
        ...filterdata,
        [name]: [], // Reset to empty array if no selection
      });
    }
    console.log(filterdata, "fil");
  };
  const handleinput = (e) => {
    setFilterdata({
      ...filterdata,
      [e.target.name]: e.target.value, // Handle the possibility of no selection
    });
  };

  const handleCheckboxChange = (e) => {
    setFilterdata({
      ...filterdata,
      withImages: e.target.checked, // Update `withImages` based on checkbox
    });
  };

  return (
    <div className="header">
      <h1 className="title">The World's No.1 Matrimonial Service</h1>
      <div className="search-box">
        {/* bride */}
        <div className="search-field">
          <label className="im">I'm looking for a</label>

          <Select
            value={
              filterdata["Bride/Bridegroom"]
                ? {
                    value: filterdata["Bride/Bridegroom"],
                    label: `${filterdata["Bride/Bridegroom"]}`,
                  }
                : null
            }
            options={gendergiv.map((val) => ({
              value: val,
              label: val,
            }))}
            onChange={(selectedOption) =>
              handleInputSome(selectedOption, "Bride/Bridegroom")
            }
            placeholder="Select"
          />
        </div>

        {/* age */}
        <div className="search-field">
          <label>Age</label>

          <input
            type="number"
            defaultValue="22"
            min="18"
            value={filterdata.ageFrom}
            name="ageFrom"
            onChange={handleinput}
          />
          <span>to</span>
          <input
            type="number"
            defaultValue="27"
            min="18"
            value={filterdata.ageTo}
            name="ageTo"
            onChange={handleinput}
          />
        </div>

        {/* height */}
        <div className="search-field">
          <label>Height </label>
          {/* <select name="heightfrom" onChange={handleInputChange}>
            <option>Select</option>
            {generateHeightOptions().map((religion, index) => (
              <option key={index} value={religion}>
                {religion}
              </option>
            ))}
          </select> */}
          <Select
            options={[
              { value: "", label: "None" }, // Deselect option
              ...generateHeightOptions().map((val) => ({
                value: val,
                label: val,
              })),
            ]}
            onChange={(selectedOption) =>
              handleInputSome(selectedOption, "heightfrom")
            }
            placeholder="Select"
          />
          <span>to</span>
          {/* <select name="heightto" onChange={handleInputChange}>
            <option>Select</option>
            {generateHeightOptions().map((religion, index) => (
              <option key={index} value={religion}>
                {religion}
              </option>
            ))}
          </select> */}

          <Select
            options={[
              { value: "", label: "None" },
              ...generateHeightOptions().map((val) => ({
                value: val,
                label: val,
              })),
            ]}
            onChange={(selectedOption) =>
              handleInputSome(selectedOption, "heightto")
            }
            placeholder="Select"
          />
        </div>
        {/* denomination */}
        <div className="search-field">
          <label>Denomination</label>

          <DropdownWithCheckbox
            options={religionOptions} // Options list
            selectedOptions={filterdata.Denomination} // Currently selected values
            onSelectionChange={
              (selectedOptions) =>
                handleInputChange(selectedOptions, "Denomination") // Call to update selected options
            }
          />
        </div>
        {/* cast */}

        <div className="search-field">
          <label>Cast</label>
          {/* <Select
            options={[
              { value: "", label: "None" },
              ...casteOptions.map((caste) => ({
                value: caste,
                label: caste,
              })),
            ]}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "Cast")
            }
            placeholder="Select"
          /> */}
          <DropdownWithCheckbox
            options={casteOptions} // Options list
            selectedOptions={filterdata.Cast} // Currently selected values
            onSelectionChange={
              (selectedOptions) => handleInputChange(selectedOptions, "Cast") // Call to update selected options
            }
          />
        </div>
        {/* education */}

        <div className="search-field">
          <label>Education</label>
          {/* <Select
            options={[
              { value: "", label: "None" },
              ...education.map((educat) => ({
                value: educat,
                label: educat,
              })),
            ]}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "Education")
            }
            placeholder="Select"
          /> */}

          <DropdownWithCheckbox
            options={education} // Options list
            selectedOptions={filterdata.Education} // Currently selected values
            onSelectionChange={
              (selectedOptions) =>
                handleInputChange(selectedOptions, "Education") // Call to update selected options
            }
          />
        </div>
        {/* profession */}
        <div className="search-field">
          <label>Profession</label>
          {/* <Select
            options={[
              { value: "", label: "None" },
              ...professions.map((profess) => ({
                value: profess,
                label: profess,
              })),
            ]}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "Profession")
            }
            placeholder="Select"
          /> */}

          <DropdownWithCheckbox
            options={professions} // Options list
            selectedOptions={filterdata.Profession} // Currently selected values
            onSelectionChange={
              (selectedOptions) =>
                handleInputChange(selectedOptions, "Profession") // Call to update selected options
            }
          />
        </div>

        {/* maital status */}
        <div className="search-field">
          <label>Marital Status</label>

          {/* <Select
            options={[
              { value: "", label: "None" },
              ...maritial.map((val) => ({
                value: val,
                label: val,
              })),
            ]}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "MaritalStatus")
            }
            placeholder="Select"
          /> */}

          <DropdownWithCheckbox
            options={maritial} // Options list
            selectedOptions={filterdata.MaritalStatus} // Currently selected values
            onSelectionChange={
              (selectedOptions) =>
                handleInputChange(selectedOptions, "MaritalStatus") // Call to update selected options
            }
          />
        </div>
        {/* city */}
        <div className="search-field">
          <label>City</label>

          {/* <Select
            options={[
              { value: "", label: "None" },
              ...cityOptions.map((val) => ({
                value: val,
                label: val,
              })),
            ]}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "City")
            }
            placeholder="Select"
          /> */}

          <DropdownWithCheckbox
            options={cityOptions} // Options list
            selectedOptions={filterdata.City} // Currently selected values
            onSelectionChange={
              (selectedOptions) => handleInputChange(selectedOptions, "City") // Call to update selected options
            }
          />
        </div>
        {/* country */}
        <div className="search-field">
          <label>Country</label>

          {/* <Select
            options={[
              { value: "", label: "None" },
              ...regionOptions.map((val) => ({
                value: val,
                label: val,
              })),
            ]}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "Country")
            }
            placeholder="Select"
          /> */}

          <DropdownWithCheckbox
            options={regionOptions} // Options list
            selectedOptions={filterdata.Country} // Currently selected values
            onSelectionChange={
              (selectedOptions) => handleInputChange(selectedOptions, "Country") // Call to update selected options
            }
          />
        </div>
        {/* salary */}
        <div className="search-field">
          <label>Salary</label>

          {/* <Select
            options={[
              { value: "", label: "None" },
              ...salaryOptions.map((val) => ({
                value: val,
                label: val,
              })),
            ]}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "salary")
            }
            placeholder="Select"
          /> */}
          <DropdownWithCheckbox
            options={salaryOptions} // Options list
            selectedOptions={filterdata.salary} // Currently selected values
            onSelectionChange={
              (selectedOptions) => handleInputChange(selectedOptions, "salary") // Call to update selected options
            }
          />
        </div>

        <div className="search-field withimages">
          <label htmlFor="withimg">With Images</label>
          <input
            id="withimg"
            type="checkbox"
            name="withImages"
            checked={filterdata.withImages}
            onChange={handleCheckboxChange}
          />
        </div>

        <button className="search-button" onClick={handleFilter}>
          Search
        </button>
      </div>
    </div>
  );
};

export default Filteration;
