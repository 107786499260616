import React, { useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import "../../styles/uploadimg.css";
import axios from "axios";
import CookieService from "encrypted-cookie";
import { FaXmark } from "react-icons/fa6";
import src from "encrypted-cookie";

const AddUploadImg = () => {
  const [images, setImages] = useState([]);
  const [countimg, setCountImg] = useState(0);
  const [jioid, setJioid] = useState("");
  const [newImg, setNewImg] = useState([]);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);

  useEffect(() => {
    const per = localStorage.getItem("userimages");
    const data = JSON.parse(per);

    if (data) {
      setImages(data);
      setCountImg(data.length);
    }

    const personalData = localStorage.getItem("personal");
    if (personalData) {
      const alldata = JSON.parse(personalData);
      if (alldata.JIOID) {
        setJioid(alldata.JIOID);
      }
    }
  }, []);

  const handleImageChange = (event) => {
    const files = event.target.files;

    const fileArray = Array.from(files);
    const imgPromises = fileArray.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    });

    Promise.all(imgPromises)
      .then((imgarr) => {
        setNewImg(imgarr);
      })
      .catch((error) => {
        console.error("Error reading files:", error);
      });
  };

  const handleUpload = async () => {
    const customHeaders = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };

    setIsUploading(true);
    setUploadError(null);
    setUploadSuccess("");

    try {
      const response = await axios.post(
        `/api/profile/updateImages`,
        {
          newImg,
          jioid, // Include jioid in the request body
        },
        {
          headers: customHeaders,
        }
      );

      const data = response.data;
      console.log("Success:", response, data);
      // const imageDataAll = data.imageAll?.map((obj) => obj.imageData) || [];
      // Update state and local storage
      setImages(data.imageAll);
      setCountImg(data.imageAll.length);
      setUploadSuccess(data.message);
      localStorage.setItem("userimages", JSON.stringify(data.imageAll));

      // Set success state
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      setIsUploading(false);
      setNewImg([]); // Clear newImg after upload
    }
  };

  // const handleDelete = async (filname) => {
  //   const customHeaders = {
  //     "Content-Type": "application/json",
  //     Authorization: CookieService.getCookie("token"),
  //   };
  //   console.log(filname);

  //   try {
  //     const response = await axios.delete(`/api/profile/deleteImg`, {
  //       headers: customHeaders,
  //       data: { filname, jioid }, // Include jioid in the request body here
  //     });

  //     const data = response.data;

  //     setImages(data.imageData);
  //     setCountImg(data.imageData.length);
  //     setUploadSuccess(true);
  //     localStorage.setItem("userimages", JSON.stringify(data.imageData));
  //   } catch (error) {
  //     console.error("Error deleting images:", error);
  //   } finally {
  //     setIsUploading(false);
  //     setNewImg([]); // Clear newImg after upload
  //   }
  // };

  const confirmDelete = async (filename) => {
    console.log(filename, "filename");
    const customHeaders = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };

    try {
       const response = await axios.delete(`/api/profile/deleteImg`, {
         headers: customHeaders,
         data: { filname: filename, jioid },
       });

      const data = response.data;
      setImages(data.imageData);
      setCountImg(data.imageData.length);
      setUploadSuccess(data.message);
      localStorage.setItem("userimages", JSON.stringify(data.imageData));
    } catch (error) {
      console.error("Error deleting images:", error);
    } finally {
      setShowConfirm(false); // Close the popup
      setImageToDelete(null); // Clear the image to delete
    }
  };

  const handleDelete = (filename) => {
    setImageToDelete(filename);
    setShowConfirm(true); // Show confirmation popup
  };

  // return (
  //   <div className="add-upload-section">
  //     <div>
  //       <h1 className="upload-img">
  //         You have added {countimg} photos. Add more to get better response.
  //       </h1>
  //       {images.length === 0 && (
  //         <p style={{ textAlign: "center" }}>No images added yet.</p>
  //       )}

  //       {uploadError && <p className="error-text">{uploadError}</p>}
  //       {uploadSuccess && (
  //         <p className="success-text">Images uploaded successfully!</p>
  //       )}
  //       {isUploading && <p className="loading-text">Uploading images...</p>}

  //       <div className="images-list">
  //         {images.map((each, index) => (
  //           <div className="imagesDiv">
  //             <img
  //               key={index}
  //               className="list-images"
  //               src={`data:image/jpeg;base64,${each.imageData}`}
  //               alt={`Profile ${index}`}
  //             />

  //             <FaXmark
  //               size={30}
  //               color="#fff"
  //               className="xmark"
  //               onClick={() => handleDelete(each.filename)}
  //             />
  //           </div>
  //         ))}
  //         <div className="add-img">
  //           <input
  //             type="file"
  //             accept="image/*"
  //             multiple
  //             onChange={handleImageChange}
  //             style={{ display: "none" }}
  //             id="file-upload"
  //           />
  //           <label htmlFor="file-upload" className="upload-label">
  //             <CiCirclePlus className="plus-icon" />
  //             <p>Add More Images</p>
  //           </label>
  //         </div>
  //       </div>
  //       {newImg.length > 0 && !isUploading && (
  //         <button onClick={handleUpload} className="upload-button">
  //           Upload Images
  //         </button>
  //       )}
  //     </div>
  //   </div>
  // );

  return (
    <div className="add-upload-section">
      <div>
        <h1 className="upload-img">
          You have added {countimg} photos. Add more to get better response.
        </h1>
        {images.length === 0 && (
          <p style={{ textAlign: "center" }}>No images added yet.</p>
        )}

        {uploadError && <p className="error-text">{uploadError}</p>}
        {uploadSuccess && <p className="success-text">{uploadSuccess}</p>}
        {isUploading && <p className="loading-text">Uploading images...</p>}

        <div className="images-list">
          {images.map((each, index) => (
            <div className="imagesDiv" key={index}>
              <img
                className="list-images"
                src={`data:image/jpeg;base64,${each.imageData}`}
                alt={`Profile ${index}`}
              />
              <FaXmark
                size={30}
                color="#fff"
                className="deletexmark"
                onClick={() => handleDelete(each.filename)}
              />
            </div>
          ))}
          <div className="add-img">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="file-upload"
            />
            <label htmlFor="file-upload" className="upload-label">
              <CiCirclePlus className="plus-icon" />
              <p>Add More Images</p>
            </label>
          </div>
        </div>

        {newImg.length > 0 && !isUploading && (
          <button onClick={handleUpload} className="upload-button">
            Upload Images
          </button>
        )}

        {showConfirm && (
          <div className="confirmation-popup">
            <div className="popup-content">
              <h3>Are you sure you want to delete this image?</h3>
              <div className="popup-buttons">
                <button onClick={() => confirmDelete(imageToDelete)}>
                  Yes
                </button>
                <button onClick={() => setShowConfirm(false)}>No</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddUploadImg;
