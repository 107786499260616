import React, { useState } from "react";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";

function Feedback() {
  // Step 1: Define state for form inputs
  const [formData, setFormData] = useState({
    Name: "",
    email: "",
    feedback: "",
    rating: "",
  });

  const [responseMessage, setResponseMessage] = useState(null);

  // Step 2: Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value, // Dynamically update the field
    });
  };

  // Step 3: Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      // Step 4: Send email using EmailJS
      const response = await emailjs.send(
        "service_k6xbprr", // Your EmailJS service ID
        "template_xrmyszi", // Your EmailJS template ID
        {
          from_name: formData.Name,
          from_email: formData.email,
          feedback: formData.feedback,
          rating: formData.rating,
        },
        "9_oyxuC8ExmviTN2z" // Your EmailJS user ID
      );

      toast.success("Feedback submitted successfully!");
      setResponseMessage("Feedback submitted successfully!");

      // Reset form fields after successful submission
      setFormData({
        Name: "",
        email: "",
        feedback: "",
        rating: "",
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);

      toast.error("An error occurred. Please try again later.");
      setResponseMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="feedback-container">
      {/* <img src={require("../../images/feed.avif")} alt="Feedback" /> */}
      {/* <img src={"https://i.pinimg.com/550x/8c/11/72/8c1172abad10245fac2fdea591b122a4.jpg"} alt="Feedback" /> */}
      <img src={"https://i.pinimg.com/564x/7d/28/3e/7d283e6401a3df9c6d268263a7a960b0.jpg"} alt="Feedback" style={{filter:"brightness(150%) saturate(100%) contrast(125%)", height:"70vh", width:"100%"}} />
      {/* <img src={"https://i.pinimg.com/564x/f6/9e/bb/f69ebb2a45a6579d2cd81f006f7ea342.jpg"} alt="Feedback" style={{height:"70vh"}} /> */}

      <div className="feedback-form-container">
        <h2>
          We Value Your <span>Feedback</span>
        </h2>
        <form className="feedback-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              id="name"
              name="Name"
              value={formData.Name}
              onChange={handleInputChange}
              placeholder="Enter your name"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="feedback">Your Feedback</label>
            <textarea
              id="feedback"
              name="feedback"
              rows="5"
              value={formData.feedback}
              onChange={handleInputChange}
              placeholder="Share your thoughts"
              required
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="rating">Rate Us</label>
            <select
              id="rating"
              name="rating"
              value={formData.rating}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Rating</option>
              <option value="5">Excellent</option>
              <option value="4">Very Good</option>
              <option value="3">Good</option>
              <option value="2">Fair</option>
              <option value="1">Poor</option>
            </select>
          </div>

          <button type="submit" className="submit-btn">
            Submit Feedback
          </button>
        </form>
        {responseMessage && <p>{responseMessage}</p>}
      </div>
    </div>
  );
}

export default Feedback;
