import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const isRegistered = localStorage.getItem("personalDetails") === "true";
  console.log(isRegistered, "register");
   return isRegistered ? <Outlet /> : <Navigate to="/Register" />;
};

export default ProtectedRoute;
