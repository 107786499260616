import React, { useEffect, useState } from "react";
import axios from "axios";
import CookieService from "encrypted-cookie";
import { useParams } from "react-router-dom";
import "../../styles/UserDetails.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import defaultAvatar from "../../images/OIP.jpeg";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";

const UserDetail = () => {
  const { jioId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showVal, setShowVal] = useState(false);
  const [errorContact, setErrorContact] = useState(null);
  const [remainingDays, setRemainingDays] = useState(null);
  const [contactLimit, setContactLimit] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const handleReveal = () => {
    const personalDataString = localStorage.getItem("personal");
    const personalData = JSON.parse(personalDataString);

    axios
      .get(`/api/users/CheckContactLimit`, {
        params: { id: personalData.JIOID },
      })
      .then((response) => {
        if (response.data.message === "Contact Revealed") {
          if (response.data.remainingDays) {
            const { months, days } = response.data.remainingDays;
            if (months === 0 && days === 0) {
              setErrorContact("Your Package is Expired");
              return;
            }
            console.log(response.data);
          
            setRemainingDays(
              `${months} month${months !== 1 ? "s" : ""} and ${days} day${days !== 1 ? "s" : ""}`
            );
          }
          setContactLimit(response.data.limitcount);
          setShowVal(true);
          setErrorContact(null);
        }
      })
      .catch((error) => {
        setErrorContact(error.response?.data?.error || "Error fetching contact limit.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = CookieService.getCookie("token");
      const custom_headers = { Authorization: token };
      const personalDataString = localStorage.getItem("personal");
      const personalData = JSON.parse(personalDataString);
      const viewerJioId = personalData.JIOID;
    
      try {
        // Fetch user details
        const response = await axios.get(`/api/users/GetUsersById?id=${jioId}`, {
          headers: custom_headers,
        });
    
        setUserDetails(response.data.users[0]);
        setImages(response.data.users[0].images);
    
        // Log the profile view
        await axios.post("/api/users/logView", {
          viewerJioId: viewerJioId,
          viewedProfileJioId: jioId,
        }, {
          headers: custom_headers,
        });
    
        // Fetch current user's favorites
        const favoritesResponse = await axios.get(`/api/users/getFavorites`, {
          headers: custom_headers,
          params: { userId: viewerJioId }, // Add user ID here
        });
    
        // Check if the current user is a favorite
        setIsFavorite(favoritesResponse.data.favorites.includes(response.data.users[0].JIOID));
    
      } catch (error) {
        console.error("Error fetching user details or logging view:", error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };
    

    if (jioId) {
      fetchUserDetails();
    }
  }, [jioId]);

  const toggleFavorite = async () => {
    const personalDataString = localStorage.getItem("personal");
    const personalData = JSON.parse(personalDataString);
    const loggedInUserId = personalData.JIOID;

    setIsLoadingFavorite(true);
    try {
      const token = CookieService.getCookie("token");
      const headers = { Authorization: token };

      if (isFavorite) {
        await axios.post('/api/users/removeFavorite', {
          userId: loggedInUserId,
          favoriteJioId: userDetails.JIOID
        }, { headers });
        setIsFavorite(false);
      } else {
        await axios.post('/api/users/addFavorite', {
          userId: loggedInUserId,
          favoriteJioId: userDetails.JIOID
        }, { headers });
        setIsFavorite(true);
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    } finally {
      setIsLoadingFavorite(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="user-detail">
      <div className="userImage">
        {images && images.length > 0 ? (
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="images">
                {image ? (
                  <img
                    src={`data:image/jpeg;base64,${image}`}
                    alt={`User Image ${index + 1}`}
                  />
                ) : (
                  <img
                    src={defaultAvatar}
                    alt="Default Avatar"
                    className="default-avatar"
                  />
                )}
              </div>
            ))}
          </Slider>
        ) : (
          <img
            src={defaultAvatar}
            alt="Default Avatar"
            className="default-avatar"
          />
        )}
      </div>

      <div className="userDetails">
        <div className="name">
          <h2>{userDetails.Name}</h2>
          <div className="favorite-icon" onClick={toggleFavorite}>
            {isLoadingFavorite ? (
              <span>Loading...</span>
            ) : isFavorite ? (
              <MdFavorite color="red" size={30} />
            ) : (
              <MdFavoriteBorder size={30} />
            )}
          </div>
        </div>
        <div className="aboutme">
          <p>
            <strong>J{userDetails.JIOID}</strong>
          </p>
          <p>
            <strong>About Me:</strong> {userDetails.AboutMe || "Not Specified"}
          </p>
        </div>
        <div className="basic-information">
          <p>
            <strong>Age:</strong> {userDetails.Age || "Not Specified"}
          </p>
          <p>
            <strong>Height:</strong> {userDetails.height || "Not Specified"}
          </p>
          <p>
            <strong>Gender:</strong> {userDetails.gender || "Not Specified"}
          </p>
          <p>
            <strong>Education:</strong>{" "}
            {userDetails.Education || "Not Specified"}
          </p>
          <p>
            <strong>Profession:</strong>{" "}
            {userDetails.Profession || "Not Specified"}
          </p>
          <p>
            <strong>Salary (PA):</strong>{" "}
            {userDetails.SalaryPA_Standardized || "Not Specified"}
          </p>
          <p>
            <strong>Date of Birth:</strong>{" "}
            {new Date(userDetails.DateOfBirth).toLocaleDateString() ||
              "Not Specified"}
          </p>
          <p>
            <strong>Marital Status:</strong>{" "}
            {userDetails.MaritalStatus || "Not Specified"}
          </p>
        </div>
        <div className="family">
          <p>
            <strong>About Family:</strong>{" "}
            {userDetails.AboutFamily || "Not Specified"}
          </p>
          <p>
            <strong>Preferred Match:</strong>{" "}
            {userDetails.Prefering || "Not Specified"}
          </p>
        </div>
        <div className="adress">
          <p>
            <strong>Address:</strong> {userDetails.Address || "Not Specified"}
          </p>
          <p>
            <strong>City:</strong> {userDetails.City || "Not Specified"}
          </p>
          <p>
            <strong>Country:</strong> {userDetails.Country || "Not Specified"}
          </p>
          <p>
            <strong>Cast:</strong> {userDetails.Cast || "Not Specified"}
          </p>
        </div>
        <div className="conatctinfo">
          {!showVal ? (
            <span
              onClick={handleReveal}
              className="line-shade"
              style={{ width: "fit-content" }}
            >
              Show Contact Details
            </span>
          ) : (
            <>
              <p>Mobile Number: {userDetails.Mobile || "Not Specified"}</p>
              <p>Email: {userDetails.email || "Not Specified"}</p>
              {remainingDays ? (
                <p style={{ color: "#ff6f61" }}>
                  You have <strong>{remainingDays}</strong> remaining and can
                  reveal <strong>{contactLimit}</strong> more contacts.
                </p>
              ) : (
                <p style={{ color: "#ff6f61" }}>
                  You can reveal <strong>{contactLimit}</strong> more contacts.
                </p>
              )}
            </>
          )}
          {errorContact && <p className="error-message">{errorContact}</p>}
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
