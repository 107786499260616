import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/notfound.css"
const NotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/"); // Redirects to the home page
  };

  return (
    <div className="not-found-container">
      <img src={require("../images/404.webp")}/>
      <p className="not-found-message">
        Oops! The page you are looking for doesn't exist.
      </p>
      <button className="not-found-button" onClick={goHome}>
        Go Home
      </button>
    </div>
  );
};

export default NotFound;
