import React from "react";
import "../../styles/privacy_policies.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy">
      <center>
        <h2>PRIVACY STATEMENT</h2>
      </center>
      <p>
        When you purchase something from our store, as part of the buying and
        selling process, we collect the personal information you provide us such
        as your name, address, and email address.
      </p>
      <p>
        When you browse our store, we automatically receive your computer’s
        internet protocol (IP) address to provide us with information that helps
        us learn about your browser and operating system.
      </p>
      <p>
        Email marketing (if applicable): With your permission, we may send you
        emails about our store, new products, and other updates.
      </p>
      <h4>CONSENT:</h4>
      <p>
        When you provide us with personal information to complete a transaction,
        verify your credit card, place an order, arrange for a delivery, or
        return a purchase, we imply that you consent to our collecting and using
        it for that specific purpose only.
      </p>
      <p>
        If we ask for your personal information for a secondary reason, like
        marketing, we will either ask you directly for your expressed consent or
        provide you with an opportunity to decline.
      </p>
      <p>
        If, after you opt-in, you change your mind, you may withdraw your
        consent for us to contact you, or for the continued collection, use, or
        disclosure of your information at any time, by contacting us at{" "}
        <a href="mailto:contact@jiomatrimony.com">contact@jiomatrimony.com</a>{" "}
        or mailing us at: www.jiomatrimony.com, Spec Computers and
        Communications, Room No. 203, Second Floor, Plot No. 1588, D.No. 9-1-134
        to 137, Amruthavani Communication Centre, Sebastian Road, Patny Center
        Circle, Secunderabad, Hyderabad, Telangana, 500003.
      </p>
      <h4>DISCLOSURE:</h4>
      <p>
        We may disclose your personal information if required by law or if you
        violate our Terms of Service.
      </p>
      <p>
        Payment Policy: All payments made to www.jiomatrimony.com for any
        subscription service are non-refundable. Agreed service charges are
        payable upon confirmation of marriage.
      </p>
      <p>
        Upon marriage confirmation, you shall send the family details of the
        person with whom you got engaged.
      </p>
      <p>
        You shall send the wedding card via email and WhatsApp to JioMatrimony.
        You shall also send the wedding photos via email and WhatsApp to
        JioMatrimony. Service charges must be paid on or before the engagement
        if you find a partner through JioMatrimony or its associates.
      </p>

      {/* <p>
        JioMatrimony members may not assign or transfer their membership to any
        other person or entity. Such acts shall be treated as violations of our
        terms and conditions, and JioMatrimony retains its rights under these
        terms and conditions.
      </p>
      <p>
        Payments made for JioMatrimony services cannot be reassigned to another
        person or adjusted toward any other product or package provided by the
        company.
      </p> */}
      <h4>THIRD-PARTY SERVICES:</h4>
      <p>
        Generally, the third-party providers we use will only collect, use, and
        disclose your information to the extent necessary to allow them to
        perform the services they provide to us.
      </p>
      <p>
        However, certain third-party service providers, such as payment gateways
        and transaction processors, have their own privacy policies regarding
        the information we must provide for your purchase-related transactions.
      </p>
      <p>
        We recommend reading these providers' privacy policies to understand how
        your personal information will be handled by them.
      </p>
      <p>
        Note that certain providers may be located in or have facilities in a
        different jurisdiction than you or us. Therefore, if you proceed with a
        transaction involving a third-party service provider, your information
        may be subject to the laws of the jurisdiction(s) in which that provider
        or its facilities are located.
      </p>
      <p>
        Once you leave our store’s website or are redirected to a third-party
        website or application, you are no longer governed by this Privacy
        Policy or our website’s Terms of Service.
      </p>
      <h4>Links:</h4>
      <p>
        When you click on links on our store, they may direct you away from our
        site. We are not responsible for the privacy practices of other sites
        and encourage you to read their privacy statements.
      </p>
      <h4>SECURITY:</h4>
      <p>
        To protect your personal information, we take reasonable precautions and
        follow industry best practices to ensure it is not lost, misused,
        accessed, disclosed, altered, or destroyed inappropriately.
      </p>
      <h4>COOKIES:</h4>
      <p>
        We use cookies to maintain your session. Cookies are not used to
        personally identify you on other websites.
      </p>
      <h4>AGE OF CONSENT:</h4>
      <p>
        By using this site, you represent that you are at least the age of
        majority in your state or province of residence, or that you are the age
        of majority in your state or province and have given us your consent to
        allow any of your minor dependents to use this site.
      </p>
      <h4>CHANGES TO THIS PRIVACY POLICY:</h4>
      <p>
        We reserve the right to modify this privacy policy at any time, so
        please review it frequently. Changes and clarifications will take effect
        immediately upon their posting on the website. If we make material
        changes to this policy, we will notify you here that it has been
        updated, so you are aware of what information we collect, how we use it,
        and under what circumstances, if any, we use and/or disclose it.
      </p>
      <p>
        If our store is acquired or merged with another company, your
        information may be transferred to the new owners to continue selling
        products to you.
      </p>
      <h4>QUESTIONS AND CONTACT INFORMATION:</h4>
      <p>
        If you would like to: access, correct, amend, or delete any personal
        information we have about you, register a complaint, or simply want more
        information, contact our Privacy Compliance Officer at{" "}
        <a href="mailto:contact@jiomatrimony.com">contact@jiomatrimony.com</a>{" "}
        or mail us at: www.jiomatrimony.com, Spec Computers and Communications,
        Room No. 203, Second Floor, Plot No. 1588, D.No. 9-1-134 to 137,
        Amruthavani Communication Centre, Sebastian Road, Patny Center Circle,
        Secunderabad, Hyderabad, Telangana, 500003.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
