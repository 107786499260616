import React from "react";
import "../../styles/privacy_policies.css";

const Refund_Policies = () => {
  return (
    <div className="privacy">
      <center>
        <h2>Refund Policy</h2>{" "}
      </center>
      <p style={{ textAlign: "center" }}>
        If anyone takes subscription and want to cancel amount will be refunded
        in clients account in 2-3 days
      </p>

      {/* <h4>Returns:</h4>
      <p>
        Our policy lasts 30 days. If 30 days have gone by since your purchase,
        unfortunately we can’t offer you a refund or exchange.
      </p>
      <p>
        To be eligible for a return, your item must be unused and in the same
        condition that you received it. It must also be in the original
        packaging.
      </p>
      <p>
        Several types of goods are exempt from being returned. Perishable goods
        such as food, flowers, newspapers or magazines cannot be returned. We
        also do not accept products that are intimate or sanitary goods,
        hazardous materials, or flammable liquids or gases.
      </p>
      <center>
        <h2>Additional non-returnable items</h2>{" "}
      </center>
      <h4>Gift cards:</h4>
      <p>
        Downloadable software products. Some health and personal care items.To
        complete your return, we require a receipt or proof of purchase.
      </p>
      <p>Please do not send your purchase back to the manufacturer.</p>
      <p>
        There are certain situations where only partial refunds are granted: (if
        applicable) Book with obvious signs of use CD, DVD, VHS tape, software,
        video game, cassette tape, or vinyl record that has been opened
      </p>
      <p>
        Any item not in its original condition, is damaged or missing parts for
        reasons not due to our error.
      </p>
      <p>Any item that is returned more than 30 days after delivery</p>
      <h4>Refunds (if applicable):</h4>
      <p>
        Once your return is received and inspected, we will send you an email to
        notify you that we have received your returned item. We will also notify
        you of the approval or rejection of your refund.If you are approved,
        then your refund will be processed, and a credit will automatically be
        applied to your credit card or original method of payment, within a
        certain amount of 7 days.{" "}
      </p>
      <h4>Late or missing refunds (if applicable)</h4>
      <p>
        If you haven’t received a refund yet, first check your bank account
        again.
      </p>
      <p>
        Then contact your credit card company, it may take some time before your
        refund is officially posted.
      </p>
      <p>
        Next contact your bank. There is often some processing time before a
        refund is posted.
      </p>
      <p>
        If you’ve done all of this and you still have not received your refund
        yet, please contact us at (contact@jiomatrimony.com) or mailing us at:
        www.jiomatrimony.com , spec computers and communications, Room No.203,
        Second floor, Plot No.1588, D.No.9-1-134 to 137, Amruthavani
        Communication Centre, Sebastian Road, Patny Center Circle, Secunderabad,
        Hyderabad, Telangana, 500003.
      </p>
      <h4>Exchanges (if applicable)</h4>
      <p>
        We only replace items if they are defective or damaged. If you need to
        exchange it for the same item, send us an email at
        (contact@jiomatrimony.com ) or mailing us at: www.jiomatrimony.com ,
        spec computers and communications, Room No.203, Second floor, Plot
        No.1588, D.No.9-1-134 to 137, Amruthavani Communication Centre,
        Sebastian Road, Patny Center Circle, Secunderabad, Hyderabad, Telangana,
        500003.
      </p>
      <h4>Gifts:</h4>
      <p>
        If the item was marked as a gift when purchased and shipped directly to
        you, you’ll receive a gift credit for the value of your return. Once the
        returned item is received, a gift certificate will be mailed to you.
      </p>
      <p>
        If the item wasn’t marked as a gift when purchased, or the gift giver
        had the order shipped to themselves to give to you later, we will send a
        refund to the gift giver and he will find out about your return.
      </p>
      <h4>Shipping:</h4>
      <p>
        To return your product, you should mail your product to: spec computers
        and communications, Room No.203, Second floor, Plot No.1588,
        D.No.9-1-134 to 137, Amruthavani Communication Centre, Sebastian Road,
        Patny Center Circle, Secunderabad, Hyderabad, Telangana, 500003
      </p>
      <p>
        You will be responsible for paying for your own shipping costs for
        returning your item. Shipping costs are non-refundable. If you receive a
        refund, the cost of return shipping will be deducted from your refund.
      </p>
      <p>
        Depending on where you live, the time it may take for your exchanged
        product to reach you, may vary.
      </p>
      <p>
        Copyrights Policy No material from this site or any other web site
        owned, operated, controlled or licensed by www.jiomatrimony.com and/or
        associates or sister concerns may be copied, reproduced, republished,
        transmitted, downloaded, uploaded or in any other manner for commercial
        use or otherwise without written permission of www.jiomatrimony.com .
        Violation of this condition is a violation of copyright and other
        proprietary rights of www.jiomatrimony.com and/or their associates or
        sister concerns or affiliates.
      </p>
      <h4>Disclaimers:</h4>
      <p>
        www.jiomatrimony.com IS PROVIDING THIS SITE AND ITS CONTENTS "AS IS" AND
        MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WITH RESPECT TO THIS
        SITE OR ITS CONTENTS. www.jiomatrimony.com DISCLAIMS ALL SUCH
        REPRESENTATIONS AND WARRANTIES, INCLUDING FOR EXAMPLE WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT.
        IN ADDITION, www.jiomatrimony.com DOES NOT REPRESENT OR WARRANT THAT THE
        INFORMATION ACCESSIBLE VIA THIS SITE IS ACCURATE, COMPLETE OR CURRENT.
        TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, JioMatrimony IS NOT
        LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
        PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES
        FOR LOSS OF PROFITS, REVENUE, GOODWILL, USE, DATA, ELECTRONICALLY
        TRANSMITTED ORDERS, OR OTHER ECONOMIC ADVANTAGE, HOWEVER CAUSED AND
        REGARDLESS OF THE THEORY OF LIABILITY, WHETHER IN CONTRACT (INCLUDING
        FUNDAMENTAL BREACH), TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, ARISING
        OUT OF OR
      </p>
      <h4>RELATED TO:</h4>
      <p>
        THE USE OF OR THE INABILITY TO USE THE WEBSITE; THE COST OF PROCUREMENT
        OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA,
        INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR
        TRANSACTIONS ENTERED INTO THROUGH OR FROM THE WEBSITE; UNAUTHORIZED
        ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; STATEMENTS OR
        CONDUCT OF ANY THIRD PARTY ON THE WEBSITE; OR ANY OTHER MATTER RELATING
        TO THE SERVICES, INCLUDING CONTENT YOU MAY DOWNLOAD, USE, MODIFY OR
        DISTRIBUTE FROM www.jiomatrimony.com WEB SITE. YOU HAVE SOLE
        RESPONSIBILITY FOR ADEQUATE PROTECTION AND BACKUP OF DATA AND/OR
        EQUIPMENT USED IN CONNECTION WITH THE WEBSITE AND WILL NOT MAKE A CLAIM
        AGAINST www.jiomatrimony.com FOR LOST DATA, RE-RUN TIME, INACCURATE
        OUTPUT, WORK DELAYS OR LOST PROFITS RESULTING FROM THE USE OF THE
        CONTENT. YOU AGREE TO HOLD www.jiomatrimony.com HARMLESS FROM, AND YOU
        COVENANT NOT TO SUE www.jiomatrimony.com FOR, ANY CLAIMS BASED ON USING
        THE WEBSITE, WHETHER IN CONTRACT (INCLUDING FUNDAMENTAL BREACH), TORT
        (INCLUDING NE GLIGENCE) OR OTHERWISE.
      </p>
      <p>
        Because some states do not allow the exclusion or limitation of
        liability for consequential or incidental damages, in such states
        liability is limited to the fullest extent permitted by law.
        Notwithstanding the foregoing, in no event www.jiomatrimony.com be
        liable to the user for any or all damages, losses, and causes of action
        (including but not limited to, negligence) or otherwise exceeding the
        amount paid by the user to www.jiomatrimony.com for that specific
        service/product.
      </p>
      <p>
        THE USER OF www.jiomatrimony.com ,IS PRESUMED TO HAVE READ ALL THE TERMS
        AND CONDITIONS HEREIN AND FAQ AND IS DEEMED TO HAVE AGREED, UNDERSTOOD
        AND ACCEPTED UNCONDITIONALLY ALL THE TERMS, CONDITIONS, PROCEDURES AND
        RISKS OF USING THE SERVICES AND CAN NOT AT ANY TIME CLAIM IGNORANCE OF
        ANY OR ALL OF THEM. ALL RELATIONSHIP OF ANY USER WHERESOEVER SITUATED IS
        GOVERNED BY AND IN ACCORDANCE WITH THE LAWS OF ANDHRA PRADESH, INDIA.
      </p> */}
    </div>
  );
};

export default Refund_Policies;
