// import React, { useState, useRef, useEffect } from "react";
// import "./checkbox.css";
// import { SlArrowDown, SlArrowUp } from "react-icons/sl";

// const DropdownWithCheckbox = (props) => {
//   const { onSelectionChange, options, selectedOptions } = props;
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const dropdownRef = useRef(null); // Ref for detecting outside clicks
//   const [currentSelectedList, setCurrentSelectedList] = useState([]);

//   // Toggle the dropdown visibility
//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };
//   console.log(currentSelectedList, "current");
//   // Handle checkbox change
//   const handleCheckboxChange = (option) => {
//     setCurrentSelectedList((prevState) => {
//       const updatedSelections = prevState.includes(option)
//         ? prevState.filter((id) => id !== option)
//         : [...prevState, option];

//       onSelectionChange(updatedSelections); // Call the function to update the parent state
//       return updatedSelections; // Return the new state for the current component
//     });
//   };

//   // Close dropdown if clicked outside
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsDropdownOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);
//   console.log(selectedOptions)
//   const selectedCount = selectedOptions.length;
//   const headerLabel = selectedCount ? `${selectedCount} Selected` : "Select";
//   console.log(selectedCount, "selectedCount", headerLabel);

//   return (
//     <div className="dropdown" ref={dropdownRef}>
//       <div
//         className="dropdown-header"
//         onClick={toggleDropdown}
//         aria-expanded={isDropdownOpen}
//       >
//         {headerLabel}
//         <span className="arrow">
//           {isDropdownOpen ? <SlArrowUp /> : <SlArrowDown />}
//         </span>
//       </div>

//       {isDropdownOpen && (
//         <div className="dropdown-menu">
//           {options.map((option) => (
//             <div key={option} className="dropdown-item">
//               <input
//                 type="checkbox"
//                 checked={currentSelectedList.includes(option)}
//                 onChange={() => handleCheckboxChange(option)}
//               />
//               <label>{option}</label>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default DropdownWithCheckbox;
import React, { useState, useRef, useEffect } from "react";
import "./checkbox.css";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";

const DropdownWithCheckbox = (props) => {
  const { onSelectionChange, options, selectedOptions } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [currentSelectedList, setCurrentSelectedList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (!isDropdownOpen) {
      setSearchTerm(""); // Clear search term when opening dropdown
    }
  };

  const handleCheckboxChange = (option) => {
    setCurrentSelectedList((prevState) => {
      const updatedSelections = prevState.includes(option)
        ? prevState.filter((id) => id !== option)
        : [...prevState, option];

      onSelectionChange(updatedSelections);
      return updatedSelections;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectedCount = selectedOptions.length;
  const headerLabel = selectedCount ? `${selectedCount} Selected` : "Select";

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div
        className="dropdown-header"
        onClick={toggleDropdown}
        aria-expanded={isDropdownOpen}
      >
        <input
          type="text"
          className="search-input"
          placeholder={headerLabel}
          style={{
            border: "none",
            outline: "none",
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <span className="arrow">
          {isDropdownOpen ? <SlArrowUp /> : <SlArrowDown />}
        </span>
      </div>

      {isDropdownOpen && (
        <div className="dropdown-menu">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div key={option} className="dropdown-item">
                <input
                  type="checkbox"
                  checked={currentSelectedList.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                />
                <label>{option}</label>
              </div>
            ))
          ) : (
            <div className="no-options">No options found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownWithCheckbox;
