import React, { useState } from "react";
import "./model.css";
import Box from "@mui/material/Box";
import Modals from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "10px 50px",
  maxHeight: "80vh",
  overflow: "auto",
};

const Modal = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCurrentIndex(0);
  };

  const handleNext = () => {
    if (currentIndex < data.imageData.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
      <div className="card-img">
        {data.imageData && data.imageData.length > 0 ? (
          <>
            <img
              className="img-Featured"
              src={`data:image/jpeg;base64,${data.imageData[currentIndex]}`}
              alt={`Profile ${data._id} image ${currentIndex + 1}`}
            />
            {data.imageData.length > 1 && (
              <button onClick={handleOpen}>Show All Images</button>
            )}
          </>
        ) : (
          <img src={require("../../images/OIP.jpeg")} alt="Default" />
        )}
      </div>
      <Modals
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        fullwidth
      >
        <Box sx={style}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <h2 id="image-modal-title">
                Image {currentIndex + 1} of{" "}
                {data.imageData ? data.imageData.length : 0}
              </h2>
            </Grid>
            <Grid item>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
            </Grid>
          </Grid>

          <div id="image-modal-description" className="image-container">
            {data.imageData && data.imageData.length > 0 && (
              <>
                <img
                  className="img-back"
                  src={`data:image/jpeg;base64,${data.imageData[currentIndex]}`}
                  alt={`Profile ${data._id} image ${currentIndex + 1}`}
                  style={{
                    width: "280px",
                    maxHeight: "45%",

                    marginBottom: "10px",
                  }}
                />
                <div className="navigation-buttons">
                  <button
                    onClick={handlePrevious}
                    disabled={currentIndex === 0}
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNext}
                    disabled={currentIndex === data.imageData.length - 1}
                  >
                    Next
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modals>
    </>
  );
};

export default Modal;
